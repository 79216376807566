import { Header } from "../Home/Header";
import bannerrightshape from "../../../Assets/img/banner/1.jpg";
import React, { useEffect,useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Footer } from "../../Client/Home/Footer";
import ScrollToTop from "react-scroll-to-top";
import H1BLoginView from "../H1BLogin/H1BLoginView";

function SelectedH1BCandidate() {
    
    const location = useLocation();
    const h1bCandidateid=(location?.state?.H1bCandiateId??0);
    const [Auth, SetAuth] = useState();
    useEffect(()=>{
        SetAuth(h1bCandidateid);
    })
    return Auth ? (
        <>
          <Header id={h1bCandidateid} />
          <div
                className="breadcrumb-area text-center shadow dark text-light bg-cover"
                style={{
                    backgroundImage: `url(${bannerrightshape})`,
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <h1>NEW H1B PETITIONS 2024</h1>
                            <ul className="breadcrumb">
                         <li className="active">Submit the below application now to ensure swift processing and timely inclusion in the lottery.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <p>Welcome</p>
            </div>
            <ScrollToTop smooth color="#2b5690" />
            <Footer />
        </>
    ): (
        <H1BLoginView/>
      );
}
export default SelectedH1BCandidate