import AxiosServices from "./AxiosServices";

const Auth = new AxiosServices();

class H1B_Services{
   

      Add_ApplierDetails = async (data) => {
        
        return await Auth.post(`H1B/Add_ApplierDetails`, data);
      };
      PassportUpload = async (uploadresume) => {
        
         return await Auth.post('H1B/PassportUpload', uploadresume, {
          "content-type": "multipart/form-data"
        });
      };

      Get_H1B_Appliers_Lists = async (selectedYear,selectedMonth,selectedH1B) => {
        debugger
       
        return await Auth.get(`/H1b/Get_H1B_Appliers_Lists?selectedYear=${selectedYear}&selectedMonth=${selectedMonth}&selectedH1B=${selectedH1B}`);

      };

     
      ViewApplierById = async (ID) => {
        
        return  await Auth.get(`H1B/ViewApplierById?id=${ID}`);
      };
      GetCountryList  = async () => {
        return await Auth.get(`H1B/GetCountryList`);
      };
      StatusUpdation = async (value,ID) => {
        
        return await Auth.get(`H1B/StatusUpdation?Status=${value}&id=${ID}`);
      };
      GetCradentialMailContent = async () => {
       
        return await Auth.get('/H1b/GetCradentialMailContent');

      };
      SendPreviewMail = async (mailData) => {
         return await Auth.post(`H1B/SendPreviewMail`, mailData);
 
       };
      

}
export default H1B_Services