import React from "react";
import AxiosServices from "./AxiosServices";

const Auth = new AxiosServices();

class H1BLoginServices{
    getData = async (authentication) => {
        
        return Auth.post("/H1BLogin/Authenticate", authentication);
      };
      
  getOtpData = async (Username) => {
    return Auth.get(`/H1BLogin/OTPPassword?userName=${Username}`);
  };
  Changepassword = async (data) => {
    // return Auth.post('/Login/ChangePasswordFromOTP', data)
    return Auth.post("/H1BLogin/ChangePasswordFromOTP", data);
  };
  
  verifyotp = async (data) => {
    return Auth.post("/H1BLogin/ForgotpasswordCheckOtp", data);
  };

}

export default H1BLoginServices;