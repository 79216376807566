import React, { useState } from "react";
import InquiryServices from "../../../Services/InquiryServices";
import "jquery";
import $ from "jquery";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
var InquiryServicesObj = new InquiryServices();

function Contactarea() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
    project: "",
  });
  const cookies = new Cookies();
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 750,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };
  function numberOnly(e) {
    var element = document.getElementById(e);
    element.value = element.value.replace(/[^0-9]/gi, "");
  }

  const name = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.name = "Name is Required";
    } else {
      myerror.name = "";
    }
    setError({ ...myerror });
  };
  // const email = (e) => {
  //   var myerror = error;
  //   if (e.target.value == null || e.target.value == "") {
  //     myerror.email = "Email is Required";
  //   } else {
  //     myerror.email = "";
  //   }
  //   setError({ ...myerror });
  // };

  const email = (e) => {
    var myerror = error;
    // e = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (e.target.value == null || e.target.value == "") {
      myerror.email = "Email is Required";
    } else {
      myerror.email = "";
    }
    setError({ ...myerror });
  };

  const phone = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.phone = "Phone is Required";
    } else {
      myerror.phone = "";
    }
    setError({ ...myerror });
    numberOnly("phone");
  };

  const companyName = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.companyName = "Company Name is Required";
    } else {
      myerror.companyName = "";
    }
    setError({ ...myerror });
  };

  const project = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.project = "Project Requirement is Required";
    } else {
      myerror.project = "";
    }
    setError({ ...myerror });
  };

  const onSubmit = (res) => {

    res.preventDefault();

    var myerror = error;

    var flag = true;
    var data = {
      TalkID: 0,
      Name: document.getElementById("name").value,
      Email: document.getElementById("email").value,
      Phone: document.getElementById("phone").value,
      CompanyName: document.getElementById("companyName").value,
      ProjectRequirement: document.getElementById("project").value,
    };

    if (data.Name == null || data.Name == "") {
      myerror.name = "Name is Required";
      flag = false;
    } else {
      myerror.name = "";
    }

    // if (data.Email != null && data.Email != "") {
    //   myerror.email = "";
    // } else {
    //   myerror.email = "Email is Required";
    //   flag = false;
    // }

    // if (data.Email == null || data.Email == "") {
    //   myerror.email = "Email is Required";
    //   flag = false;
    // } else {
    //   myerror.email = "";
    // }

    if (data.Email == null || data.Email == "") {
      myerror.email = "Email is Required";
      flag = false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.Email)) {
      console.log(data.Email);
      myerror.email = "Valid Email is Required";
      flag = false;
    } else {
      myerror.email = " ";
    }

    if (data.Phone == null || data.Phone == "") {
      myerror.phone = "Phone is Required";
      flag = false;
    } else {
      myerror.phone = "";
    }

    if (data.CompanyName == null || data.CompanyName == "") {
      myerror.companyName = "Company Name is Required";
      flag = false;
    } else {
      myerror.companyName = "";
    }

    if (data.ProjectRequirement == null || data.ProjectRequirement == "") {
      myerror.project = "Project Requirement is Required";
      flag = false;
    } else {
      myerror.project = "";
    }
    setError({ ...myerror });

    if (flag) {
      // const formData = new FormData();
      console.log(data);
      InquiryServicesObj.Create(data).then(async (res) => {
        if (res.data == 1) {
          $(".close").trigger("click");

          Toast.fire({
            icon: "success",
            title: "Submitted Successfully",
          });
          setTimeout(function () {
            window.location.reload();
          }, 750);
        } else {
          Toast.fire({
            icon: "error",
            title: "Failed to Save!",
          });
        }
      });
      setIsLoaded(true);
    }
  };

  return (
    <>
      {/* Start Contact Area 
      ============================================= */}
      <div className="contact-area default-padding bg-theme inc-shape">
        <div className="container">
          <div className="row align-center">
            <div className="col-lg-5 info">
              <div className="content">
                <h2>Let’s connect?</h2>

                <ul>
                  <li>
                    <i className="fas fa-map-marker-alt" />

                    <p>
                      <h6 style={{ fontWeight: "bold" }}>Corporate Office</h6>
                      <span style={{ color: "#2b5690" }}>
                        Vaanam{" "}
                        <span style={{ color: "#2b5690" }}>
                          Technologies LLC,
                        </span>{" "}
                      </span>
                      <br />
                      1740 Grassland Parkway, Suit #404, Alpharetta, <br /> GA -
                      30004, USA
                    </p>
                  </li>
                  <li>
                    <i className="fas fa-map-marker-alt" />
                    <p>
                      <h6 style={{ fontWeight: "bold" }}>
                        Offshore Development Office
                      </h6>
                      <span style={{ color: "#2b5690" }}>Vaanam </span>{" "}
                      <span style={{ color: "#2b5690" }}>
                        Technologies Pvt Ltd,
                      </span>
                      <br /> Grand CAG Central,
                      <br />
                      787, Avinashi Road, <br />
                      Nava India, Peelamedu,
                      <br />
                      Coimbatore,
                      <br />
                      Tamil Nadu - 641 004,
                      <br />
                      India.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1 contact-form-box">
              <div className="form-box">
                <form
                  onSubmit={onSubmit}
                  className="contact-form"
                  autoComplete="off"
                >
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          className="form-control"
                          id="name"
                          name="name"
                          placeholder="Name*"
                          type="text"
                          onChange={name}
                        />
                        <span className="text-danger"> {error.name}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Email*"
                          type="text"
                          onChange={email}
                        />
                        <span className="text-danger"> {error.email} </span>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          id="phone"
                          name="phone"
                          placeholder="Phone*"
                          type="text"
                          maxLength={10}
                          onKeyDown={(event) => onkeydown(event)}
                          onChange={phone}
                        />
                        <span className="text-danger"> {error.phone} </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          className="form-control"
                          id="companyName"
                          name="companyName"
                          placeholder="Company Name*"
                          type="text"
                          onChange={companyName}
                        />
                        <span className="text-danger">
                          {" "}
                          {error.companyName}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group comments">
                        <textarea
                          className="form-control"
                          id="project"
                          name="project"
                          placeholder="Project Requirement*"
                          defaultValue={""}
                          onChange={project}
                        />
                        <span className="text-danger"> {error.project} </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      {!isLoaded ? (
                        <button type="submit" name="submit" id="submit">
                          Send Message <i className="fa fa-paper-plane" />
                        </button>
                      ) : (
                        <button class="btn btn-primary" type="button" disabled>
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Loading...
                        </button>
                      )}
                    </div>
                  </div>
                  {/* Alert Message */}
                  <div className="col-lg-12 alert-notification">
                    <div id="message" className="alert-msg" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End Contact Area */}
    </>
  );
}

export default Contactarea;
