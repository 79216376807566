import React, { useState, useEffect } from "react";
import jQuery from "jquery";
import $ from "jquery";
import AddOrEditClients from "./AddOrEditClients";
import HeaderDashboard from "../DashBoard/HeaderDashboard";
import Swal from "sweetalert2";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import ScrollToTop from "react-scroll-to-top";
import Preloader from "../../Preloader";
import Cookies from "universal-cookie";
import LoginView from "../Login/LoginView";
import ClientServices from "../../../Services/ClientsServices";
var ClientServicesobj = new ClientServices();
const ClientsListTable = () => {
  const [Model, setModel] = useState({
    clientID: "",

    logoUrl: "",
    clientOrder: "",
  });
  const [ClientDetails, setClientDetails] = useState([]);
  const cookies = new Cookies();
  const [Auth, SetAuth] = useState();
  useEffect(() => {
    SetAuth(cookies.get("userID"));
    ClientServicesobj.GetClientList().then((res) => {
      if (res.data != null && res.data.length > 0) {
        setClientDetails([...res.data]);
      }
      setTimeout(() => {
        $("#preloader").hide();
      }, 1000);
    });
  }, []);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 750,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  function GetEdit(
    clientID,


    logoUrl,
    clientOrder
  ) {
    setModel({
      clientID: clientID,


      logoUrl: logoUrl,
      clientOrder: clientOrder,
    });
  }

  function Delete(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "Do You Want to Delete this Client?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        ClientServicesobj.Delete(id).then((res) => {
          if (res.data == 1) {
            Toast.fire({
              icon: "success",
              title: "Delete Success",
            });
            setTimeout(function () {
              window.location.reload();
            }, 750);
          } else {
            Toast.fire({
              icon: "error",
              title: "Delete Failed!",
            });
          }
        });
      }
    });
  }

  return Auth ? (
    <>
      <Preloader />

      <HeaderDashboard />
      <div class="main-panel">
        <div class="content">
          <div className="page-inner">
            <div class="row">
              <div class="col-md-12">
                <div class="card grid">
                  <div class="card-header">
                    <div class="d-flex align-items-center">
                      <h4 class="card-title">Clients</h4>
                      <a
                        href="#"
                        className="btn btn-primary btn-round ml-auto"
                        data-toggle="modal"
                        data-target="#addRowModal"
                      >
                        <i className="fa fa-plus"></i>
                        Add Client
                      </a>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="table-responsive">
                      <table
                        className="display table table-striped table-hover"
                        id="AssociateDisplay"
                      >
                        <thead>
                          <tr>

                            <th>Client Logo</th>


                            <th >Action(s)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {" "}
                          {ClientDetails != null &&
                            ClientDetails.length > 0 ? (
                            ClientDetails.map((v, i) => {
                              return (
                                <tr key={i}>


                                  <td>
                                    <img
                                      className="tableimage"
                                      src={
                                        process.env.REACT_APP_API_IMAGEPATH +
                                        v.logoUrl
                                      }
                                      alt="No Logo"
                                    />
                                  </td>


                                  <td>
                                    <div class="form-button-action tableoptions">
                                      <button
                                        type="button"
                                        title=""
                                        class="btn btn-link  btn-lg"
                                        data-original-title="Edit"
                                        data-toggle="modal"
                                        data-target="#addRowModal"
                                        onClick={() =>
                                          GetEdit(
                                            v.clientID,

                                            v.logoUrl,
                                            v.clientOrder
                                          )
                                        }
                                      >
                                        <FaPencilAlt />
                                      </button>

                                      <button
                                        type="button"
                                        data-toggle="tooltip"
                                        title=""
                                        class="btn btn-link "
                                        data-original-title="Remove"
                                        onClick={() =>
                                          Delete(v.clientID)
                                        }
                                      >
                                        <FaTrashAlt />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <label>No Data in Table</label>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div
          class="modal fade"
          id="addRowModal"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-backdrop="static"
        >
          <AddOrEditClients Model={Model} />
        </div>
      </div>

      <footer className="bg-dark text-light">
        {/* Start Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            <div className="footer-bottom-box">
              <div className="row">
                <div className="col-lg-12">
                  <p>
                    © Copyright 2012 - {new Date().getFullYear()}. All Rights
                    Reserved by{" "}
                    <a href="/Dashboardview"> Vaanam Technologies Pvt Ltd.</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollToTop smooth color="#2b5690" />
        {/* End Footer Bottom */}
      </footer>
    </>
  ) : (
    <LoginView />
  );
};
export default ClientsListTable;
