import { Header } from "../Home/Header";
import bannerrightshape from "../../../Assets/img/banner/1.jpg";
import React from "react";
import CareerServices from "../../../Services/CareerServices";
import { useState, useEffect } from "react";
import Apply from "./Apply";
import Swal from "sweetalert2";
import ViewRecuritment from "./ViewRecuritment";
import { Footer } from "../../Client/Home/Footer";
import ScrollToTop from "react-scroll-to-top";
import $ from "jquery";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useLocation, useNavigate } from "react-router-dom";

var CareerServicesObj = new CareerServices();
function Career() {
  const location = useLocation();
  const career = location.state?.career;
  const [CareerDetails, setCareerDetail] = useState([]);
  const [India, setIndia] = useState(false);
  const [Us, setUs] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  useEffect(() => {
    if (career)
      setSelectedTabIndex(career === "India" ? 0 : 1);
  }, [career]);


  useEffect(() => {
    CareerServicesObj.GetCareerAdmin().then((res) => {
      if (res.data != null && res.data.length > 0) {
        setCareerDetail([...res.data]);
        {
          res.data != null && res.data.length > 0 ? (

            res.data.map((v, i) => {
              if (v.workLocation == "India") {
                setIndia(true);
              }
              else if (v.workLocation == "US") {
                setUs(true);
              }
            })) : <></>
        }
      }
      setTimeout(() => {
        $("#preloader").hide();
      }, 1000);
    });
  }, []);


  const [Model, setModel] = useState({
    experience: "",
    location: "",
  });

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 750,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  return (
    <>
      <Header />
      <div
        className="breadcrumb-area text-center shadow dark text-light bg-cover"
        style={{
          backgroundImage: `url(${bannerrightshape})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>Careers</h1>
              <ul className="breadcrumb">
                <li>
                  <a href="/">
                    <i className="fas fa-home" /> Home
                  </a>
                </li>
                <li className="active">Careers</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumb */}
      {/* Start Career 
     ============================================= */}
      <div class="main-panel">
        <div class="content">
          <div class="row">
            <div class="col-md-12">
              <div class="card grid">
                <Tabs selectedIndex={selectedTabIndex} onSelect={(index) => setSelectedTabIndex(index)}>
                  <TabList>
                    <Tab>India</Tab>
                    <Tab>US</Tab>
                  </TabList>

                  {/* tab-1 ---> Jobs */}
                  <TabPanel>

                    <div className="page-inner">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="">

                            <div class="card-body">
                              <div className="vaanam-career">
                                <div className="container">
                                  <div className="job-lists">
                                    <div className="row">
                                      {India === true ?
                                        <div className="col-lg-12">
                                          {CareerDetails != null && CareerDetails.length > 0 ? (

                                            CareerDetails.map((v, i) => {


                                              if (v.workLocation == 'India') {


                                                return (
                                                  <>
                                                    <div className="item">
                                                      <div className="info">
                                                        <div className="row">
                                                          <div className="col-md-8">
                                                            {/* <div className="top">
                                  <i className="fas fa-adjust" />
                                  {v.designation}
                                </div> */}
                                                            <h4>

                                                              {v.jobTitle}{" "}
                                                              <span className="badge">Open</span>
                                                            </h4>
                                                            <ul>
                                                              <li>Job Type: {v.jobType}</li>
                                                              <li>Work Mode: {v.workMode}</li>
                                                            </ul>
                                                            <div class="form-button-action tableoptions">
                                                              <button
                                                                type="button"
                                                                id="viewMore"
                                                                class="btn btn-link  btn-lg"
                                                                data-original-title="Edit"
                                                                data-toggle="modal"
                                                                data-target="#addRowModal1"
                                                                onClick={() => setModel({ ...v })}
                                                              >
                                                                <i className="fa fa-eye recuritment"></i>
                                                                &nbsp; Viewmore
                                                              </button>
                                                            </div>
                                                          </div>

                                                          <div className="col-md-4">
                                                            <div className="Apply">
                                                              <a
                                                                className="btn btn-gradient effect btn-md"
                                                                href=""
                                                                data-toggle="modal"
                                                                data-target="#addRowModal"
                                                                onClick={() => setModel({ ...v })}
                                                              >
                                                                Apply Now
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                );
                                              }

                                            })
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                        : <div className="nojobs">Currently No Openings</div>}

                                    </div>
                                  </div>
                                </div>
                                {/* End Career */}
                                <div class="">
                                  <div
                                    class="modal fade"
                                    id="addRowModal"
                                    tabindex="-1"
                                    role="dialog"
                                    aria-hidden="true"
                                    data-bs-backdrop="static"
                                    data-backdrop="static"
                                  >
                                    <Apply Model={Model} />
                                  </div>
                                </div>
                                <div class="">
                                  <div
                                    class="modal fade "
                                    id="addRowModal1"
                                    tabindex="-1"
                                    role="dialog"
                                    aria-hidden="true"
                                    data-bs-backdrop="static"
                                    data-backdrop="static"
                                  >
                                    <ViewRecuritment Model={Model} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class=" ">
                      <div
                        class="modal fade"
                        id="addRowModal"
                        tabindex="-1"
                        role="dialog"
                        aria-hidden="true"
                        data-bs-backdrop="static"
                        data-backdrop="static"
                      ></div>
                    </div>

                  </TabPanel>

                  {/* tab-2 --> Resumes */}
                  <TabPanel>
                    <div className="page-inner">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="">

                            <div class="card-body">
                              <div className="vaanam-career">
                                <div className="container">
                                  <div className="job-lists">
                                    <div className="row">
                                      {Us === true ? <div className="col-lg-12">
                                        {CareerDetails != null && CareerDetails.length > 0 ? (
                                          CareerDetails.map((v, i) => {
                                            if (v.workLocation == 'US') {
                                              return (
                                                <>
                                                  <div className="item">
                                                    <div className="info">
                                                      <div className="row">
                                                        <div className="col-md-8">
                                                          {/* <div className="top">
                                  <i className="fas fa-adjust" />
                                  {v.designation}
                                </div> */}
                                                          <h4>

                                                            {v.jobTitle}{" "}
                                                            <span className="badge">Open</span>
                                                          </h4>
                                                          <ul>
                                                            <li>Job Type: {v.jobType}</li>
                                                            <li>Work Mode: {v.workMode}</li>
                                                          </ul>
                                                          <div class="form-button-action tableoptions">
                                                            <button
                                                              type="button"
                                                              id="viewMore"
                                                              class="btn btn-link  btn-lg"
                                                              data-original-title="Edit"
                                                              data-toggle="modal"
                                                              data-target="#addRowModal1"
                                                              onClick={() => setModel({ ...v })}
                                                            >
                                                              <i className="fa fa-eye recuritment"></i>
                                                              &nbsp; Viewmore
                                                            </button>
                                                          </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                          <div className="Apply">
                                                            <a
                                                              className="btn btn-gradient effect btn-md"
                                                              href=""
                                                              data-toggle="modal"
                                                              data-target="#addRowModal"
                                                              onClick={() => setModel({ ...v })}
                                                            >
                                                              Apply Now
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            }
                                          })
                                        ) : (
                                          <></>
                                        )}
                                      </div> : <div className="nojobs">Currently No Openings</div>}

                                    </div>
                                  </div>
                                </div>
                                {/* End Career */}
                                <div class="">
                                  <div
                                    class="modal fade"
                                    id="addRowModal"
                                    tabindex="-1"
                                    role="dialog"
                                    aria-hidden="true"
                                    data-bs-backdrop="static"
                                    data-backdrop="static"
                                  >
                                    <Apply Model={Model} />
                                  </div>
                                </div>
                                <div class="">
                                  <div
                                    class="modal fade "
                                    id="addRowModal1"
                                    tabindex="-1"
                                    role="dialog"
                                    aria-hidden="true"
                                    data-bs-backdrop="static"
                                    data-backdrop="static"
                                  >
                                    <ViewRecuritment Model={Model} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="">
                      <div
                        class="modal fade"
                        id="addRowModal"
                        tabindex="-1"
                        role="dialog"
                        aria-hidden="true"
                        data-bs-backdrop="static"
                        data-backdrop="static"
                      ></div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop smooth color="#2b5690" />
      <Footer />
    </>
  );
}
export default Career;
