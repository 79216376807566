import React, { useEffect, useState } from "react";
import $ from "jquery";
import ProjectServices from "../../../Services/ProjectServices";
import vaanamlogolight from "../../../Assets/img/Vaanam-light.png";
import vaanamlogo from "../../../Assets/img/Vaanam.png";
import { FaMailBulk, FaMobile, FaMobileAlt } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";

var ProjectServicesObj = new ProjectServices();

export const Footer = () => {
  const [ProjectDetails, setProjectDetails] = useState([]);
  const navigate = new useNavigate();
  useEffect(() => {
    ProjectServicesObj.Client_GetProjectList().then((res) => {
      if (res.data != null && res.data.length > 0) {
        setProjectDetails([...res.data]);
        $("#preloader").hide();
      }
    });
  }, []);

  const scrollToDiv = (projectID) => {

    const targetDiv = document.getElementById(`Pdescription_${projectID}`);
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: "smooth" });
    }
  };

  const navigateToCareer = (value) => {

    navigate('/Career', {
      state: { career: value },
      replace: true,
    });
  }

  const handleProjectClick = (projectID) => {
    localStorage.setItem('selectedProjectID', projectID);
    navigate('/OurProducts');
  };

  return (
    <>
      {
        <>
          <div class="footercolor text-light footerbelow">
            <div class="container">
              <div class="f-items default-padding">
                <div class="row">
                  <div class="col-lg-3 col-md-6 item">
                    <div class="f-item link"></div>
                    <a href="/" className="navbar-brand">
                      <img
                        src={vaanamlogolight}
                        className="logo logo-display"
                        alt="Logo"
                      />

                    </a>
                    <br />
                    <br />
                    <br />
                    <p></p>
                    {/* <p style={{ textAlign: "left" }}>Software Development, Consulting, and Outsourcing</p> */}
                    <div className="f-item contact-widget">
                      <div className="address">
                        <ul>
                          <li>
                            <div className="icon">
                              <i className="fas fa-phone" />
                            </div>
                            <div className="content">
                              <a href="tel:+914222567626">+91 422 256 7626</a>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <i className="fa fa-mobile-alt" />
                            </div>
                            <div className="content">
                              <a href="tel:+919043026014">&nbsp;+91 9043026014 / 25</a>
                            </div>
                          </li>
                          <li>
                            <div className="icon">
                              <i className="fas fa-envelope" />
                            </div>
                            <div className="content">
                              <a href="mailto:info@validtheme.com">info@vaanamtech.com</a>
                            </div>
                          </li>

                        </ul>
                      </div>
                    </div>
                    <br></br>
                    <ul className="social-icons">

                      <a

                        className=""
                        href="https://www.facebook.com/Vaanam-Technologies-Pvt-Ltd-506680116025192/"
                        target="_blank"
                      >
                        <i class="fab fa-facebook-f fa-2xl" style={{ fontSize: "24px" }}></i>
                      </a>
                      <a
                        className=""
                        href="https://www.linkedin.com/company/vaanam-technologies-pvt-ltd/"
                        target="_blank"
                      >
                        <i class="fab fa-linkedin-in fa-2xl" style={{ fontSize: "24px" }}></i>
                      </a>

                    </ul>

                  </div>
                  <div class="col-lg-3 col-md-6 item">
                    <div class="f-item link">
                      <h4 class="widget-title">Quick Link</h4>
                      <ul className="footerul">
                        <li>
                          <a href="/" >
                            <i class="fas fa-angle-right"></i> Home
                          </a>
                        </li>

                        <li>
                          <a href="/About">
                            <i class="fas fa-angle-right"></i> About us
                          </a>
                        </li>
                        <li>
                          <a href="/Contact">
                            <i class="fas fa-angle-right"></i> Contact us
                          </a>
                        </li>
                        <li>
                          <a href="/NewsEvents">
                            <i class="fas fa-angle-right"></i> News
                          </a>
                        </li>
                        <li>
                          <a href="#" onClick={() => navigateToCareer('India')}>
                            <i class="fas fa-angle-right"></i> Career - India
                          </a>
                        </li>
                       
                        <li>
                          <a href="#" onClick={() => navigateToCareer('US')}>
                            <i class="fas fa-angle-right"></i> Career - US
                          </a>
                        </li>
                        <li>
                          <a href="/Apply_H1B" >
                            <i class="fas fa-angle-right"></i> Apply for H1B
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 item">
                    <div class="f-item link">
                      <h4 class="widget-title">Services</h4>
                      <ul className="footerul">
                        <li>
                          <a href="/Enterprise">
                            <i class="fas fa-angle-right"></i>&nbsp;Enterprise
                            Application
                          </a>
                        </li>
                        <li>
                          <a href="/MicrosoftServices">
                            <i class="fas fa-angle-right"></i> Microsoft
                            Services
                          </a>
                        </li>
                        <li>
                          <a href="/AIML" style={{ textTransform: "initial" }}>
                            <i class="fas fa-angle-right"></i> AI and ML
                            Services
                          </a>
                        </li>
                        <li>
                          <a href="/Mobility">
                            <i class="fas fa-angle-right"></i> Mobility Services
                          </a>
                        </li>
                        <li>
                          <a href="/BusinessIntellegence">
                            <i class="fas fa-angle-right"></i> Business
                            Intelligence
                          </a>
                        </li>
                        <li>
                          <a href="/Reality">
                            <i class="fas fa-angle-right"></i> Reality
                            Technologies
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 item">
                    <div class="f-item link">
                      <h4 class="widget-title" style={{ width: "max-content" }}>
                        Our Products
                      </h4>

                      <ul className="footerul">
                        {ProjectDetails != null && ProjectDetails.length > 0
                          ? ProjectDetails.map((v, i) => (
                            <li key={i}>
                              <a
                                href={`/OurProducts?scrollToProjectID=${v.projectID}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate(`/OurProducts?scrollToProjectID=${v.projectID}`);
                                }}
                              >
                                <i className="fas fa-angle-right"></i>
                                &nbsp;{v.name}
                              </a>

                            </li>
                          ))
                          : <></>}
                      </ul>

                    </div>
                  </div>
                  {/* <div class="col-lg-3 col-md-6 item">
                    <div class="f-item link">
                      <h4 class="widget-title" style={{ width: "max-content" }}>
                        Social
                      </h4>
                      <ul className="footerul">
                  <li>
                    <a
                      className=""
                      href="https://www.facebook.com/Vaanam-Technologies-Pvt-Ltd-506680116025192/"
                    >
                      <i class="fab fa-facebook-f fa-2xl" style={{ fontSize: "24px" }}></i>
                    </a>
                  </li>

                  <li>
                    <a
                      className=""
                      href="https://www.linkedin.com/company/vaanam-technologies-pvt-ltd/"
                    >
                      <i class="fab fa-linkedin-in fa-2xl" style={{ fontSize: "24px" }}></i>
                    </a>
                  </li>
                </ul>
                    </div>
                  </div> */}
                  {/* <div class="col-lg-1 col-md-6 item">
                    <div class="f-item link"></div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* Start Footer 
      ============================================= */}
          <footer className="text-light footertab">
            {/* Start Footer Bottom */}
            <div className="footer-bottom">
              <div className="container">
                <div className="footer-bottom-box">
                  <div className="row footertext">
                    <div className="col-lg-8">
                      <p>
                        © Copyright 2012 - {new Date().getFullYear()}. All
                        Rights Reserved by{" "}
                        <a href="/"> Vaanam Technologies Pvt Ltd.</a>
                      </p>
                    </div>
                    <div className="col-lg-4 text-right link">
                      <ul>
                        <li>
                          <a href="/Terms">Terms</a>
                        </li>
                        <li>
                          <a href="/Privacy">Privacy</a>
                        </li>
                        <li>
                          <a href="/Faq">Faq</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Footer Bottom */}
          </footer>
          {/* End Footer */}
          <a id="topbutton" />
        </>
      }
    </>
  );
};
