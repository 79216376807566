import React from "react";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.css";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../Assets/css/style.css";
import LoginService from "../../../Services/LoginService";
import vaanam from "../../../Assets/img/Vaanam.png";
import login from "../../../Assets/img/login.svg";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import jQuery from "jquery";
import $ from "jquery";
import Preloader from "../../Preloader";
import LoginView from "../Login/LoginView";

export default function  ForgotView() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const [dummy, setDummy] = useState({
    prefixName: "",
    endValue: "",

    errUserName: "",
  });
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const AuthObject = new LoginService();
  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };
  const cookies = new Cookies();
  const [Auth, SetAuth] = useState();

  useEffect(() => {
    SetAuth(cookies.get("userID"));
    setTimeout(() => {
      $("#preloader").hide();
    }, 1000);
  }, []);
  const navigate = useNavigate();
  const otponchange = (e) => {
    var myerror = dummy;
    if (e.target.value == null || e.target.value == "") {
      myerror.errUserName = "Please Enter  Your Otp";
    } else {
      myerror.errUserName = "";
    }
    setDummy({ ...myerror });
    numberOnly("otp");
  };
  function numberOnly(e) {
    var element = document.getElementById(e);
    element.value = element.value.replace(/[^0-9]/gi, "");
  }
  const OTPSubmit = (e) => {
    e.preventDefault();
    var myerror = dummy;

    var flag = true;

    const obj = new FormData(e.currentTarget);

    var data = {
      userID: parseInt(cookies.get("userID")),
      otp: document.getElementById("otp").value,
    };

    if (data.otp == null || data.otp == "") {
      myerror.errUserName = "Please Enter Your Otp";
      flag = false;
    } else {
      myerror.errUserName = "";
    }
    setDummy({ ...myerror });

    if (flag == true) {
      OTP(data);
    }
  };
  const OTP = (data) => {
    AuthObject.verifyotp(data).then(
      (res) => {
        if (res.data == true) {
          window.location.href = "/ChangePassword";
        } else {
          Toast.fire({
            icon: "warning",
            title: "Please enter valid Otp",
          });
        }
      }
      // setTimeout(() => { Toast('Logged in Succesfully', 'success')}, 500)
    );
  };

  return Auth ? (
    <>
      <Preloader />
      <div className="login-area bg-gray" style={{ height: "794px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <form
                action="#"
                id="login-form"
                onSubmit={OTPSubmit}
                className="white-popup-block"
                autocomplete="off"
              >
                <div className="row align-center">
                  <div className="col-lg-5 login-social">
                    <img src={vaanam} alt="Logo" style={{ height: "60px" }} />{" "}
                    <br></br>
                    <img src={login} alt="Login" style={{ height: "263px" }} />
                  </div>
                  <div className="col-md-7 login-custom">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="form-group">
                          <label>
                            <h6>Enter Your OTP</h6>
                          </label>
                          <input
                            className="form-control"
                            placeholder="Enter Your OTP"
                            type="text"
                            id="otp"
                            maxLength={8}
                            // {...register("OTP", {
                            //   required: "OTP is Required",
                            // })}
                            value={register.OTP}
                            onChange={(e) => otponchange(e)}
                            onKeyDown={(event) => onKeyDown(event)}
                          />
                          <span className="text-danger">
                            {dummy.errUserName}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="row">
                        <div>
                          <button type="submit" id="button">
                            Continue{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <LoginView />
  );
}
