import CareerServices from "../../../Services/CareerServices";
import H1B_Services from "../../../Services/H1B_Serivices";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import $ from "jquery";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useLocation, useNavigate } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import Select from 'react-select';

const H1B_ServicesObj = new H1B_Services();

export default function ApplyForm(props) {

    const ID = props?.applierId;
    const flag = props?.flag;
    const [AppliersDetails, setAppliersDetails] = useState([]);
    const [CountryLists, setCountryLists] = useState([])
    const [Experience, setExperience] = useState([])
    const [selectExperience, setSelectExperience] = useState({});
    const [selectCountry, setSelectCountry] = useState({})
    const [selectedDate, SetSelectedDate] = useState()
    const [isLoaded, setIsLoaded] = useState(false);
    const currentDate = new Date();
    const minDate = new Date();
    minDate.setFullYear(currentDate.getFullYear() - 18);
    let setCountry = ""
    const CheckPDF=""

    const
        navigate = useNavigate();

    useEffect (() => {
        const Lable = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26","27","28","29","30","31","32"];
        const ExperienceYrs = Lable.map(e => {

            return {
                label: e,
                value: e

            }
        })
    
        if (ID > 0) {
            
            CheckAppiersDetails(ExperienceYrs)
            
           
        }
        else {

            setExperience(ExperienceYrs)
        }
       
         fetchCountry()

    }, []);

    const fetchCountry = async () => {
        await H1B_ServicesObj.GetCountryList()
            .then((res) => {
                const changeData = res?.data.map(e => {
                    return {
                        value: e.countryCode,
                        label: e.countryName
                    }
                })
                // if (setCountry != "") {
                    
                //     let check = changeData.filter(e => e.value === setCountry);
                //     setSelectCountry(check)
                // }

                setCountryLists(changeData)
                // setCountryLists(res.data[0])

            })
            .catch((err) => console.log(err));
    }
    const CheckAppiersDetails = async (ExperienceYrs = [])=>{
        H1B_ServicesObj.ViewApplierById(ID)
        .then((res) => {
            
            console.log(res.data)
            setAppliersDetails(res.data[0])
            setCountry = res && res?.data[0].cityzenShipCountry;
            const countryName= res && res?.data[0].countryName;
            const val=[{label:countryName,value:setCountry}]
            setSelectCountry(val)
            const setyrs = res && res?.data[0].totalExprience
            if (setyrs != "") {
                let check = ExperienceYrs.filter(e => e.label == setyrs);
                setSelectExperience(check)
            }
            // if (setCountry!=""){
            //     let check =
            //     setSelectCountry(check)

            // }

            setExperience(ExperienceYrs)
            
            const dob = res.data[0].dob;
            const parts = dob.split('-');
            const date = new Date(parts[2], parts[1] - 1, parts[0]);
            SetSelectedDate(date)

        })
        .catch((err) => console.log(err));
    }



    const [H1B_Details, SetH1B_Details] = useState({});
    const [passport, setPassport] = useState({});

    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const handleChange = (e) => {

        const { name, value } = e.target;

        SetH1B_Details((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const CountryListChange = (e) => {
        setSelectCountry(e)
        document.getElementById('countryError').innerText = '';


    }

    const totalExprienceChange = (e) => {
        
        setSelectExperience(e);
        document.getElementById('exprienceError').innerText = '';

    }
    const handleDateChange = (e) => {
        if (e === "") {
            $("#dOBError").text("Date is required");
        } else {
            
            SetSelectedDate(e)
            document.getElementById('dOBError').innerText = '';

        }

    };


    const handleGenderChange = (e) => {
        const { id } = e.target;
        let value = 0;

        if (id === "female") {
            value = 1;

            document.getElementById("male").checked = false;
            document.getElementById('genderErr').innerText = '';
        } else {

            document.getElementById("female").checked = false;
            document.getElementById('genderErr').innerText = '';
        }

        SetH1B_Details((prevState) => ({
            ...prevState,
            gender: value,
        }));
    };
    const handleEligibleChange = (e) => {

        const { id } = e.target;
        let value = 0;

        if (id === "Yes") {
            value = 1;

            document.getElementById("No").checked = false;
            document.getElementById('EligibleErr').innerText = '';

        } else {

            document.getElementById("Yes").checked = false;
            document.getElementById('EligibleErr').innerText = '';

        }

        SetH1B_Details((prevState) => ({
            ...prevState,
            eligibleForH1BMaster: value,
        }));
    };
    const handleWorkingChange = (e) => {

        const { id } = e.target;
        let value = 0;

        if (id === "Yes1") {
            value = 1;

            document.getElementById("No1").checked = false;
        } else {

            document.getElementById("Yes1").checked = false;
        }

        SetH1B_Details((prevState) => ({
            ...prevState,
            isWorkingUSA: value,
        }));
    };
    function resetErrorMessages() {
        // Reset error messages for all fields
        const errorElements = document.querySelectorAll('.text-danger');
        errorElements.forEach(element => {
            element.innerText = '';
        });
    }

    const formattedDate = (date) => {

        const formattedDate = new Date(date);
        const day = formattedDate.getDate();
        const month = formattedDate.getMonth()+1;
        const year = formattedDate.getFullYear();
        const FinalDate = `${day}-${month}-${year}`
        SetH1B_Details((prevState) => ({
            ...prevState,
            dOB: FinalDate,
        }));
        return FinalDate
    }
    const SelectedCountry = (country) => {

        const SelectedCountry = country.value
        SetH1B_Details((prevState) => ({
            ...prevState,
            cityzenShipCountry: SelectedCountry,
        }));
        return SelectedCountry;
    }
    const SelectedYears = (years) => {
        
        const Selectedyrs = years.value
        SetH1B_Details((prevState) => ({
            ...prevState,
            totalExprience: Selectedyrs,
        }));
        return Selectedyrs;
    }


    const onSubmit = async (data) => {
        debugger
        var flag = true;
        data.preventDefault();
        const firstName = document.getElementById('firstName').value.trim();
        const lastName = document.getElementById('lastName').value.trim();
        const emailAddress = document.getElementById('emailAddress').value.trim();
        const passportNumber = document.getElementById('passportnumber').value.trim();
        const cityzenShipCountry = selectCountry && selectCountry?.value != null ? SelectedCountry(selectCountry) : null;
        const dOB = selectedDate != null && selectedDate != undefined ? formattedDate(selectedDate) : null;
        const contactNumber = document.getElementById('contactNumber').value.trim();
        const whatsAppNumber = document.getElementById('whatsAppNumber').value.trim();
        const completeAddress = document.getElementById('completeAddress').value.trim();
        const jobRoletitle = document.getElementById('jobRoletitle').value.trim();
        const primarySkills = document.getElementById('primarySkills').value.trim();
        const totalExprience = selectExperience && selectExperience?.value != null ? SelectedYears(selectExperience) : null;
        const eligibleForH1BMaster = document.querySelector('input[name="eligibleForH1BMaster"]:checked');
        const gender = document.querySelector('input[name="gender"]:checked');

        const passportCopy = document.getElementById('passportCopy').value.trim();
        const resumeCopy = document.getElementById('resumeCopy').value.trim();
        const isWorkingUSA = document.querySelector('input[name="isWorkingUSA"]:checked');
        let firstErrorElement = null;
        if (firstName === '') {

            document.getElementById('firstNameError').innerText = 'Please enter your First Name';
            flag = false;
            
            firstErrorElement = document.getElementById('firstNameError');

        }

        if (lastName === '') {

            document.getElementById('lastNameError').innerText = 'Please enter your Last Name';
            flag = false;
            firstErrorElement = document.getElementById('lastNameError');
        }
        if (emailAddress === '') {
            document.getElementById('emailError').innerText = 'Please enter your Email';
            flag = false;
            firstErrorElement = document.getElementById('emailError');
        }
        if (passportNumber === '') {

            document.getElementById('passportError').innerText = 'Please enter your Passport Number';
            flag = false;
        }
        if (cityzenShipCountry === '' || cityzenShipCountry == null) {

            document.getElementById('countryError').innerText = 'Please select your Country';
            flag = false;
            firstErrorElement = document.getElementById('countryError');
        }
        if (dOB === '' || dOB == null) {

            document.getElementById('dOBError').innerText = 'Please select your Date of Birth';
            flag = false;
            firstErrorElement = document.getElementById('dOBError');

        }
        if (contactNumber === '') {

            document.getElementById('contactNumberError').innerText = 'Please enter your Contact Number';
            flag = false;
            firstErrorElement = document.getElementById('contactNumberError');
        }
       
        if (completeAddress === '') {

            document.getElementById('addressError').innerText = 'Please enter your Full Address';
            flag = false;
            firstErrorElement = document.getElementById('addressError');
        }
        if (jobRoletitle === '') {

            document.getElementById('jobError').innerText = 'Please enter your Job Title and Role';
            flag = false;
            firstErrorElement = document.getElementById('jobError');
        }
        if (primarySkills === '') {

            document.getElementById('PrimarySkillError').innerText = 'Please enter your Skills';
            flag = false;
            firstErrorElement = document.getElementById('PrimarySkillError');
        }
        if (totalExprience === '' || totalExprience==null) {

            document.getElementById('exprienceError').innerText = 'Please select your Experience';
            flag = false;
            firstErrorElement = document.getElementById('exprienceError');
        }
        if (eligibleForH1BMaster === null) {
            var elements = document.getElementsByName('EligibleErr');
            for (var i = 0; i < elements.length; i++) {
                elements[i].innerText = 'Please select your Eligiblility';
            }
            flag = false;
            firstErrorElement = document.getElementById('EligibleErr');

        }
        if (gender === null) {
            var elements = document.getElementsByName('genderErr');
            for (var i = 0; i < elements.length; i++) {
                elements[i].innerText = 'Please select the Gender';
            }
            flag = false;
            firstErrorElement = document.getElementById('genderErr');
        }
        if (passportCopy === '') {

            document.getElementById('PassportErr').innerText = 'Please upload your Passport';
            flag = false;
             firstErrorElement = document.getElementById('PassportErr');
        }
        if (resumeCopy === '') {

            document.getElementById('ResumeErr').innerText = 'Please upload your Resume';
            flag = false;
            firstErrorElement = document.getElementById('ResumeErr');
        }
        if (firstErrorElement) {
            
            firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'end'});
            firstErrorElement.focus(); // Set focus on the first error element
            return;
        }
        var PassPortPath;
        var ResumePath;

        if (flag === true) {
            const formData = new FormData();

            $.each($("#passportCopy")[0].files, function (i, file) {
                formData.append("Passport", file);
            });
            $.each($("#resumeCopy")[0].files, function (i, file) {
                formData.append("Resume", file);
            });
            H1B_ServicesObj.PassportUpload(formData).then((res) => {
     
                PassPortPath = res.data.fileUrl1;
                ResumePath = res.data.fileUrl2;


                if (PassPortPath != undefined && PassPortPath != null) {

                    const updatedDetails = { ...H1B_Details };
                    updatedDetails.dOB = dOB;
                    updatedDetails.cityzenShipCountry = cityzenShipCountry;
                    updatedDetails.passportnumber=passportNumber;
                    updatedDetails.totalExprience = totalExprience;
                    updatedDetails.passportCopy = PassPortPath;
                    updatedDetails.resumeCopy = ResumePath;
                    SetH1B_Details(updatedDetails)

                    H1B_ServicesObj.Add_ApplierDetails(updatedDetails).then((res) => {

                        if (res.data == 1) {

                            Toast.fire({
                                icon: "success",
                                title: "Your Application is Successfully Sumbitted",
                            });
                            setTimeout(function () {
                                //window.location.reload();
                                window.location.href="/"
                               

                            }, 2000);
                        }
                        else if (res.data == 2) {
                            Toast.fire({
                                icon: "warning",
                                title: "Already Exists... Email / Contact Number",
                            });
                            setTimeout(function () {
                               // window.location.reload();
                                window.location.href="/"
                               
                            }, 2000);
                        }
                        else {
                            Toast.fire({
                                icon: "error",
                                title: "Your File was Not Registered",
                            });
                            setTimeout(function () {
                                //window.location.reload();
                                window.location.href="/"
                            }, 2000);
                        }


                    });

                }

            })
            setIsLoaded(true);

        }



    }

    function CheckEmailChange() {
        var email = $("#emailAddress").val().trim();
        var emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z]{2,}$/i;

        if (email === "") {
            $("#emailError").text("Email is Reqiured");
        } else if (!emailRegex.test(email)) {

            $("#emailError").text("Invalid Email Address");
        } else {

            $("#emailError").text("");
        }
    }

    const passportchange = (e) => {
        ;
        const checkPDF = e.target.files[0];
    
        if (checkPDF) {
            const fileName = checkPDF.name;
            const fileExtension = fileName.split('.').pop().toLowerCase();
    
            if (fileExtension !== 'pdf') {
                 document.getElementById('PassportErr').innerText = 'Please upload a PDF formated file.';
                 e.target.value = '';
            } else {

                document.getElementById('PassportErr').innerText = '';
            }
        }
    };
    
    const ResumeChange = (e) => {


        const checkPDF = e.target.files[0];
    
        if (checkPDF) {
            const fileName = checkPDF.name;
            const fileExtension = fileName.split('.').pop().toLowerCase();
    
            if (fileExtension !== 'pdf') {
                 document.getElementById('ResumeErr').innerText = 'Please upload a PDF formated file.';
                 e.target.value = '';
            } else {

                document.getElementById('ResumeErr').innerText = '';
            }
        }

    };
    var uploadresume = { file: passport };

    function firstNameChange() {
        var FirstName = $("#firstName").val();
        if (FirstName == "") {
            $("#firstNameError").text("First Name is required");
        } else {
            $("#firstNameError").text("");
        }
    }

    function lastNameChange() {
        var FirstName = $("#lastName").val();
        if (FirstName == "") {
            $("#lastNameError").text("Last Name is required");
        } else {
            $("#lastNameError").text("");
        }
    }
    function PassportChange() {
        var FirstName = $("#passportnumber").val();
        if (FirstName == "") {
            $("#passportError").text("Passport Number is required");
        } else {
            $("#passportError").text("");
        }
    }
    function CountryChange() {
        var FirstName = $("#cityzenShipCountry").val();
        if (FirstName == "") {
            $("#countryError").text("Country is required");
        } else {
            $("#countryError").text("");
        }
    }
    function dOBChange() {
        var FirstName = $("#dOB").val();
        if (FirstName == "") {
            $("#dOBError").text("DOB is required");
        } else {
            $("#dOBError").text("");
        }
    }
    function ContactNumber() {
        var FirstName = $("#contactNumber").val();
        if (FirstName == "") {
            $("#contactNumberError").text("Contact Number is required");
        } else {
            $("#contactNumberError").text("");
        }
    }
    
    function CompleteAddressChange() {
        var FirstName = $("#completeAddress").val();
        if (FirstName == "") {
            $("#addressError").text("Please fill your Address");
        } else {
            $("#addressError").text("");
        }
    }
    function JobTittleChange() {
        var FirstName = $("#jobRoletitle").val();
        if (FirstName == "") {
            $("#jobError").text("Please fill your Job Title and Role");
        } else {
            $("#jobError").text("");
        }
    }
    function NumberChange() {
        var FirstName = $("#totalExprience").val();
        if (FirstName == "") {
            $("#exprienceError").text("Please fill your Primary Skills");
        } else {
            $("#exprienceError").text("");
        }
    }
    function PrimarySkillChange() {
        var FirstName = $("#primarySkills").val();
        if (FirstName == "") {
            $("#PrimarySkillError").text("Please fill your Primary Skills");
        } else {
            $("#PrimarySkillError").text("");
        }
    }
    function numberOnly(e) {
        const inputChar = String.fromCharCode(e.charCode);
        if (!/^\d+$/.test(inputChar)) {
            e.preventDefault();
        }
    }

    function handleKeyPress(event) {
        const keyPressed = event.key;
    
        // Check if the typed character is an alphabet
        if (/[a-zA-Z]/.test(keyPressed)) {
            // Convert the character to uppercase
            const upperCaseKey = keyPressed.toUpperCase();
    
            // Get the current cursor position
            const cursorPosition = event.target.selectionStart;
    
            // Get the input value and split it into an array of characters
            let value = event.target.value;
            let valueArray = value.split('');
    
            // Check if adding the uppercase character exceeds the maxLength
            if (value.length < event.target.maxLength) {
                // Replace the character at the cursor position with its uppercase equivalent
                valueArray.splice(cursorPosition, 0, upperCaseKey);
    
                // Set the modified value back to the input field
                value = valueArray.join('');
                event.target.value = value;
    
                // Move the cursor position one step forward
                event.target.setSelectionRange(cursorPosition + 1, cursorPosition + 1);
            }
            
            // Prevent the default behavior of input
            event.preventDefault();
        } else if (!/[a-zA-Z0-9\s]/.test(keyPressed)) {
            // Prevent the default behavior if the character is not alphanumeric
            event.preventDefault();
        }
    }
    
    
    function CantactNumberChange(e) {
        const inputValue = e.target.value;
        // Replace all characters that are not digits, plus sign, or parentheses
        const sanitizedValue = inputValue.replace(/[^\d+()]/g, '');
        // Update the input value with the sanitized value
        e.target.value = sanitizedValue;
    }
    

   






    return (
        <>
            <div className="card grid">
                {!flag &&

                    <div className="card-body">
                        <p>
                        Vaanam Technologies LLC is a leading IT consulting & software development firm founded in 2011 
                        and headquartered in Alpharetta, GA, USA is inviting applications from experienced Software & IT
                         professionals who are either living in the USA on any visa or seeking jobs in the USA from abroad 
                         and looking for H1B sponsorship. </p>
                         <p>Do you have QUESTIONS?
Drop your queries to <strong><a href="mailto:hr@vaanamtech.com"style={{color:"black"}}>hr@vaanamtech.com</a></strong> and our team will respond promptly.</p>
                    </div>
                }
                {!flag &&
                <div className="card-title">
                    <h2 className="mt-3 ml-4">Process Step</h2>
                    <div className="card-body">
                    <p>
                    Once we receive your application and find you are qualified, we will work with you for the
                     further processing of your application to ensure a timely inclusion in the H1B lottery. USCIS
                      will start accepting applications for the lottery draw from 6th March 2024 through 22nd March
                       2024 and the lottery results will be announced by end of March and if you get selected in the 
                       lottery, our office will notify you in the first week of Apr 2024 for preparing your H1B petition
                        to file it by June 30th 2024.  </p>
                        <p>Should there be any changes in the dates here, we will notify you by publishing it here and you can access
                             them in this page. For H1B transfers please write to us in the above mentioned Email Address.</p>
                    </div>
                  
                </div>
                


                }

                {!flag && <div className="card-title">
                    <h2 className="mt-3 ml-4">Submit your application</h2>
                </div>
                }
                <div className="card-body">
                    <div className="vaanam-career">
                        <div>
                            <form id="Apply" onSubmit={onSubmit} autoComplete="Off">
                                <div className="row">
                                    {flag &&
                                        <>
                                            <div className="col-md-6">
                                                <div className="form-group  ">
                                                    <label>
                                                        H1B RegisterCode
                                                    </label>

                                                    <input
                                                        type="text"
                                                        id="h1BCode"
                                                        className="form-control"
                                                        name="h1BCode"
                                                        value={AppliersDetails.h1BCode}
                                                        disabled
                                                    />

                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className="col-md-6">
                                        <div className="form-group  ">
                                            <label>
                                                First Name / Given name (as it appears on your passport)<span className="text-danger">*</span>
                                            </label>

                                            <input
                                                type="text"
                                                id="firstName"
                                                className="form-control"
                                                placeholder="First Name"
                                                name="firstName"
                                                onChange={handleChange}
                                                onBlur={firstNameChange}
                                                value={AppliersDetails.firstName}
                                                disabled={flag}

                                            />
                                            <span id="firstNameError" className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group  ">
                                            <label>
                                                Middle Name (as it appears on your passport)
                                            </label>

                                            <input
                                                type="text"
                                                id="middleName"
                                                className="form-control"
                                                placeholder={!flag ? "Middle Name" : ""}
                                                name="middleName"
                                                onChange={handleChange}
                                                value={AppliersDetails.middleName}
                                                disabled={flag}
                                            />

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group  ">
                                            <label>
                                                Last Name (as it appears on your passport)<span className="text-danger">*</span>
                                            </label>

                                            <input
                                                type="text"
                                                id="lastName"
                                                className="form-control"
                                                placeholder="Last Name"
                                                name="lastName"
                                                onChange={handleChange}
                                                onBlur={lastNameChange}
                                                value={AppliersDetails.lastName}
                                                disabled={flag}

                                            />
                                            <span id="lastNameError" className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group  ">
                                            <label>
                                                Email Address<span className="text-danger">*</span>
                                            </label>

                                            <input
                                                type="text"
                                                id="emailAddress"
                                                className="form-control"
                                                placeholder="Email Address"
                                                name="emailAddress"
                                                onChange={handleChange}
                                                onBlur={CheckEmailChange}
                                                value={AppliersDetails.emailAddress}
                                                disabled={flag}

                                            />
                                            <span id="emailError" className="text-danger"></span>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group  ">
                                            <label>
                                                Passport Number<span className="text-danger">*</span>
                                            </label>

                                            <input
                                                type="text"
                                                id="passportnumber"
                                                className="form-control"
                                                placeholder="Passport Number"
                                                name="passportnumber"
                                                onChange={handleChange}
                                                onBlur={PassportChange}
                                                value={AppliersDetails.passportnumber}
                                                disabled={flag}
                                                onKeyPress={handleKeyPress}
                                                maxLength={12}


                                            />
                                            <span id="passportError" className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group  ">
                                            <label>
                                                Your Country of Citizenship<span className="text-danger">*</span>
                                            </label>
                                            <Select
                                                options={CountryLists}
                                                name="cityzenShipCountry"
                                                value={selectCountry}
                                                placeholder="Search or select option..."
                                                onChange={CountryListChange}
                                                isSearchable
                                                isDisabled={flag}


                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        minHeight: '40px',
                                                    }),
                                                    menu: (provided, state) => ({
                                                        ...provided,
                                                        maxHeight: '200px',
                                                        overflowY: 'hidden',
                                                    }),
                                                   
                                                }}
                                            />

                                            <span id="countryError" className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                Date of Birth<span className="text-danger">*</span>
                                            </label>
                                            <ReactDatePicker
                                                className="form-control"
                                                placeholderText="DD/MM/YYYY"
                                                selected={selectedDate}
                                                dateFormat="dd/MM/yyyy"
                                                onChange={handleDateChange}
                                                disabled={flag}
                                                 maxDate={minDate}
                                                 showYearDropdown 
                                                 showMonthDropdown 
                                                 scrollableYearDropdown 
                                                 scrollableMonthYearDropdown
                                                 yearDropdownItemNumber={100} 
                                                
                                              
                                            />
                                           

                                            <span id="dOBError" className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                Gender<span className="text-danger">*</span>
                                            </label>
                                            <div className="form-check radiooption">
                                                <div>
                                                    {ID > 0 ? (
                                                        <>

                                                            <label htmlFor="male" className="radio-inline">
                                                            <input
                                                                    type="radio"
                                                                    id="male"
                                                                    name="gender"
                                                                    onChange={handleGenderChange}
                                                                    checked={AppliersDetails.gender == '0' ? true : false}
                                                                    disabled={flag}
                                                                />
                                                                Male </label>
                                                           
                                                                <label htmlFor="female" className="radio-inline">
                                                                <input
                                                                    type="radio"
                                                                    id="female"
                                                                    name="gender"
                                                                    checked={AppliersDetails.gender == '1' ? true : false}
                                                                    onChange={handleGenderChange}
                                                                    disabled={flag}
                                                                />
                                                                Female </label>
                                                            
                                                        </>
                                                    ) : (
                                                        <>
                                                            
                                                            <label htmlFor="male" className="radio-inline">
                                                                <input
                                                                    type="radio"
                                                                    id="male"
                                                                    name="gender"
                                                                    onChange={handleGenderChange}
                                                                    disabled={flag}
                                                                />
                                                                Male</label>
                                                            
                                                                <label htmlFor="female" className="radio-inline">
                                                                    <input
                                                                    type="radio"
                                                                    id="female"
                                                                    
                                                                    name="gender"
                                                                    onChange={handleGenderChange}
                                                                    disabled={flag}
                                                                />
                                                                Female</label>
                                                        
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <span id="genderErr" name="genderErr" className="text-danger"></span>

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group  ">
                                            <label>
                                                Contact Number (include country code for example:+919876543210)<span className="text-danger">*</span>
                                            </label>

                                            <input
                                                type="tel"
                                                id="contactNumber"
                                                className="form-control"
                                                placeholder="Contact Number"
                                                name="contactNumber"
                                                onChange={handleChange}
                                                onBlur={ContactNumber}
                                                value={AppliersDetails.contactNumber}
                                                disabled={flag}
                                                onInput={CantactNumberChange}
                                                maxLength={16}

                                            />
                                            <span id="contactNumberError" className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group  ">
                                            <label>
                                                Whatsapp Number  (include country code for example:+919876543210)
                                            </label>

                                            <input
                                                type="tel"
                                                id="whatsAppNumber"
                                                className="form-control"
                                               
                                                placeholder= {!flag ? "WhatsApp Number" : ""}
                                                name="whatsAppNumber"
                                                onChange={handleChange}
                                               
                                                value={AppliersDetails.whatsAppNumber}
                                                disabled={flag}
                                                onInput={CantactNumberChange}
                                                maxLength={16}

                                            />
                                            <span id="whatNumberError" className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                Provide your complete address where you currently locate<span className="text-danger">*</span>
                                            </label>

                                            <textarea
                                                type="text"
                                                id="completeAddress"
                                                className="form-control"

                                                name="completeAddress"
                                                onChange={handleChange}
                                                onBlur={CompleteAddressChange}
                                                value={AppliersDetails.completeAddress}
                                                disabled={flag}

                                            />
                                            <span id="addressError" className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group  ">
                                            <label>
                                                Your current Job Title and Job Role (For example: job tittle may be 'Senior associate' and job role can be 'Java developer')<span className="text-danger">*</span>
                                            </label>

                                            <input
                                                type="text"
                                                id="jobRoletitle"
                                                className="form-control"
                                                placeholder="Job Role And Title"
                                                name="jobRoletitle"
                                                onChange={handleChange}
                                                onBlur={JobTittleChange}
                                                value={AppliersDetails.jobRoletitle}
                                                disabled={flag}

                                            />
                                            <span id="jobError" className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group  ">
                                            <label>
                                                Major IT Specialty (Primary Skills comma separated  for example: java,c#,python)<span className="text-danger">*</span>
                                            </label>

                                            <input
                                                type="text"
                                                id="primarySkills"
                                                className="form-control"
                                                placeholder="Primary Skills"
                                                name="primarySkills"
                                                onChange={handleChange}
                                                onBlur={PrimarySkillChange}
                                                value={AppliersDetails.primarySkills}
                                                disabled={flag}

                                            />
                                            <span id="PrimarySkillError" className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                Total experience in years<span className="text-danger">*</span>
                                            </label>
                                            <Select
                                                options={Experience}
                                                name="totalExprience"
                                                value={selectExperience}
                                                onChange={totalExprienceChange}
                                                placeholder="Search or select option..."
                                                isSearchable
                                                isDisabled={flag}

                                                styles={{
                                                    control: (provided, state) => ({
                                                        ...provided,
                                                        minHeight: '40px',
                                                    }),
                                                    menu: (provided, state) => ({
                                                        ...provided,
                                                        maxHeight: '200px',
                                                        overflowY: 'hidden',
                                                    }),
                                                }}
                                            />
                                            <span id="exprienceError" className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                Have you studied in USA and obtained U.S. masters degree or higher or obtaining it by Jun 30 2024? This information is required to understand if you are eligible for H1B Master's quota.
                                                <span className="text-danger">*</span>
                                            </label>
                                              
                                            <div className="form-check radiooption">
                                                <div >
                                                    {ID > 0 ? (
                                                        <>

                                                            <label htmlFor="Yes"  className="radio-inline">
                                                                <input
                                                                    type="radio"
                                                                    id="Yes"
                                                                   
                                                                    name="eligibleForH1BMaster"
                                                                    onChange={handleEligibleChange}
                                                                    disabled={flag}
                                                                    checked={AppliersDetails.eligibleForH1BMaster == '1' ? true : false}
                                                                />Yes</label>
                                                            
                                                                <label htmlFor="No"  className="radio-inline">
                                                                <input
                                                                    type="radio"
                                                                    id="No"
                                                                   
                                                                    name="eligibleForH1BMaster"
                                                                    onChange={handleEligibleChange}
                                                                    disabled={flag}
                                                                    checked={AppliersDetails.eligibleForH1BMaster == '0' ? true : false}
                                                                />No</label>
                                                            
                                                        </>
                                                    ) : (
                                                        <>
                                                          
                                                                <label htmlFor="Yes"   className="radio-inline">
                                                                    
                                                                <input
                                                                    type="radio"
                                                                    id="Yes"
                                                                
                                                                    name="eligibleForH1BMaster"
                                                                    onChange={handleEligibleChange}
                                                                    disabled={flag}
                                                                />
                                                                Yes</label>
                                                         
                                                                
                                                                <label htmlFor="No"  className="radio-inline">
                                                                <input
                                                                    type="radio"
                                                                    id="No"
                                                                    name="eligibleForH1BMaster"
                                                                    onChange={handleEligibleChange}
                                                                    disabled={flag}
                                                                />
                                                                No</label>
                                                            
                                                        </>
                                                    )}
                                                </div>
                                                <span id="EligibleErr" name="EligibleErr" className="text-danger"></span>                                                </div>

                                        </div>
                                    </div>

                                    {!flag && <>
                                        <div className="col-sm-6">
                                            <div className="form-group ">
                                                <label>
                                                    Upload Current Passport (.pdf)<span className="text-danger">*</span>{" "}
                                                </label>
                                                <input
                                                    id="passportCopy"
                                                    type="file"
                                                    accept=".pdf"
                                                    className="form-control"
                                                    name="passportCopy"
                                                    onChange={passportchange}
                                                    disabled={flag}
                                                />
                                                <span id="PassportErr" className="text-danger"></span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group ">
                                                <label>
                                                    Upload Updated Resume (.pdf)<span className="text-danger">*</span>{" "}
                                                </label>
                                                <input
                                                    id="resumeCopy"
                                                    type="file"
                                                    accept=".pdf"
                                                    className="form-control"
                                                    name="resumeCopy"
                                                    onChange={ResumeChange}

                                                />
                                                <span id="ResumeErr" className="text-danger"></span>
                                            </div>
                                        </div>
                                    </>}

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                Are you already working on a client project and presently living in USA?
                                            </label>
                                            <div className="form-check radiooption">
                                                <div>
                                                    {ID > 0 ? (
                                                        <>
                                                           
                                                                <label htmlFor="Yes1"  className="radio-inline">
                                                                    
                                                                <input
                                                                    type="radio"
                                                                    id="Yes1"
                                                                    name="isWorkingUSA"
                                                                    onChange={handleWorkingChange}
                                                                    disabled={flag}
                                                                    checked={AppliersDetails.isWorkingUSA == '1' ? true : false}
                                                                />
                                                                Yes</label>
                                                         
                                                                <label htmlFor="No1"   className="radio-inline">
                                                                    
                                                                <input
                                                                    type="radio"
                                                                    id="No1"
                                                                    name="isWorkingUSA"
                                                                    onChange={handleWorkingChange}
                                                                    disabled={flag}
                                                                    checked={AppliersDetails.isWorkingUSA == '0' ? true : false}
                                                                />No
                                                                </label>
                                                            
                                                        </>
                                                    ) : (
                                                        <>
                                                            <label htmlFor="Yes1"  className="radio-inline">
                                                                <input
                                                                    type="radio"
                                                                    id="Yes1"
                                                                    name="isWorkingUSA"
                                                                    onChange={handleWorkingChange}
                                                                    disabled={flag}
                                                                />
                                                                Yes</label>
                                                           

                                                             
                                                                <label htmlFor="No1"   className="radio-inline">
                                                                <input
                                                                    type="radio"
                                                                    id="No1"
                                                                  
                                                                    name="isWorkingUSA"
                                                                    onChange={handleWorkingChange}
                                                                    disabled={flag}
                                                                />
                                                                No</label>
                                                         
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group  ">
                                            <label>
                                                Provide your Linkedin URL
                                            </label>

                                            <input
                                                type="text"
                                                id="linkedInURL"
                                                className="form-control"
                                                placeholder=""
                                                name="linkedInURL"
                                                onChange={handleChange}
                                                value={AppliersDetails.linkedInURL}
                                                disabled={flag}

                                            />
                                            <span className="text-danger"></span>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group  ">
                                            <label>
                                                If applicable, provide referred by  persons full name, phone number, email
                                            </label>

                                            <input
                                                type="text"
                                                id="refferedByPerson"
                                                className="form-control"
                                                placeholder=""
                                                name="refferedByPerson"
                                                onChange={handleChange}
                                                value={AppliersDetails.refferedByPerson}
                                                disabled={flag}

                                            />
                                            <span className="text-danger"></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group  ">
                                            <label>
                                                Provide here any questions or other information that you want to share with us such as if you  already have an approved  H1B   and require H1B transfer etc
                                            </label>

                                            <textarea
                                                type="text"
                                                id="anyQuestions"
                                                className="form-control"
                                                name="anyQuestions"
                                                onChange={handleChange}
                                                value={AppliersDetails.anyQuestions}
                                                disabled={flag}

                                            />
                                            <span className="text-danger"></span>
                                        </div>
                                    </div>
                                </div>

                                {!flag && <div className="text-end" >

                                    {!isLoaded ? (
                                        <button type="submit" id="addRowButton" className="btn btn-primary" style={{ cursor: "pointer" }}>
                                            Apply
                                        </button>
                                    ) : (
                                        <button class="btn btn-primary" type="button" disabled>
                                            <span
                                                class="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                            Loading...
                                        </button>
                                    )}
                                    {/* <button type="submit" id="addRowButton" class="btn btn-primary" style={{ cursor: "pointer" }}>
                                        Apply
                                    </button> */}

                                    <button
                                        type="button"
                                        className="btn btn-danger ms-2"
                                        data-bs-dismiss="modal"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => navigate("/")}
                                    >
                                        Cancel
                                    </button>
                                </div >}

                            </form>
                        </div>
                    </div>

                </div>
            </div>

        </>

    )
}