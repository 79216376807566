import React from "react";
import jQuery from "jquery";
import $ from "jquery";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.css";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../Assets/css/style.css";
import LoginService from "../../../Services/LoginService";
import vaanam from "../../../Assets/img/Vaanam.png";
import login from "../../../Assets/img/login.svg";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import Preloader from "../../Preloader";
import H1BLoginServices from "../../../Services/H1BloginServices";
const AuthObject = new H1BLoginServices();
export default function H1BForgetView() {
const navigate= useNavigate()
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({
        mode: "onChange",
      });
      const [isLoaded, setIsLoaded] = useState(false);
    const [dummy, setDummy] = useState({
        prefixName: "",
        endValue: "",
        errUserName: "",
      });
    useEffect(() => {
        setTimeout(() => {
          $("#preloader").hide();
        }, 1000);
      }, []);
    const ForgetpassSubmit = (e) => {
        e.preventDefault();
        var myerror = dummy;
    
        var flag = true;
    
        const obj = new FormData(e.currentTarget);
        var data = {
          UserName: e.currentTarget.UserName.value,
        };
    
        if (data.UserName == null || data.UserName == "") {
          myerror.errUserName = "Please Enter Username";
          flag = false;
        } else {
          myerror.errUserName = "";
        }
        setDummy({ ...myerror });
        if (flag == true) {
          setIsLoaded(true);
          AuthObject.getOtpData(data.UserName).then((res) => {
            if (res.data > 0) {
             
              Toast.fire({
                icon: "success",
                title: "Otp sent to your mail",
              });
              setTimeout(function () {
                // window.location.href = "/H1BOtpView?h1BCandidateId=" + res.data;
                // window.location.href="/H1BOtpView"
                navigate("/H1BOtpView",{state:{H1bCandiateId:res.data}})
              }, 1000);
              setIsLoaded(false);
            } else {
              setIsLoaded(false);
              Toast.fire({
                icon: "warning",
                title: "Please enter valid username",
              });
            }
          });
        }
      };
      const onKeyDown = (event) => {
        if (event.code === "Space") event.preventDefault();
      };
      const validateUser = (e) => {
        var myerror = dummy;
        if (e.target.value == null || e.target.value == "") {
          myerror.errUserName = "Please Enter  Username";
        } else {
          myerror.errUserName = "";
        }
        setDummy({ ...myerror });
      };
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
    return (
        <>
          <Preloader />
          <div className="login-area bg-gray" style={{ height: "713px" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <form
                    action="#"
                    id="login-form"
                    onSubmit={ForgetpassSubmit}
                    className="white-popup-block"
                    autocomplete="off"
                  >
                    <div className="row align-center">
                      <div className="col-lg-5 login-social">
                        <img src={vaanam} alt="Logo" style={{ height: "60px" }} />{" "}
                        <br></br>
                        <img src={login} alt="Login" style={{ height: "263px" }} />
                      </div>
                      <div className="col-md-7 login-custom">
                        <div className="col-lg-12">
                          <div className="row">
                            <div className="form-group">
                              <label>
                                <h6>Enter Username</h6>
                              </label>
                              <input
                                type="text"
                                name="UserName"
                                maxLength={50}
                                className="form-control"
                                placeholder="Enter Username or Email"
                                // {...register("UserName", {
                                //   required: "UserName is Required",
                                // })}
                                onChange={(e) => validateUser(e)}
                                value={register.UserName}
                                onKeyDown={(event) => onKeyDown(event)}
                              />
                              <span className="text-danger">
                                {dummy.errUserName}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="row">
                            <div>
                              {!isLoaded ? (
                                <button type="submit">Continue </button>
                              ) : (
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  disabled
                                >
                                  <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  Loading...
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      );
}