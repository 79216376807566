import about from "../../../Assets/img/about/1.png";
export const About = () => (
  <>
    {/* Start About 
      ============================================= */}
    <div className="about-us-area default-padding bg-gray">
      <div className="container">
        <div className="about-items">
          <div className="row align-center">
            <div className="col-lg-6">
              <div className="thumb wow fadeInUp">
                <img src={about} alt="Thumb" />
                <h2 className="wow fadeInLeft" data-wow-delay="300ms">
                  <strong>10</strong> years of <br /> business experience
                </h2>
              </div>
            </div>
            <div className="col-lg-6 info wow fadeInRight">
              <h2>
                Welcome to <br />
                Vaanam Technologies Pvt Ltd
              </h2>
              <h4>We Combine Technology with Business Requirements</h4>
              <p>
                Vaanam is a Software Development,
                Consulting, and Outsourcing Company was founded in Chicago US in
                2011. Our dedicated ODC in Coimbatore, India established in 2012
                with a pool of talented techies.
              </p>
              <p>
                Vaanam is an emerging leader in the Enterprise Applications and
                IT Services marketplace.
              </p>
              <div className="torch-red shadow bluebtn">
                <a
                  data-animation="animated zoomInUp"
                  // className="btn btn-theme effect btn-md"
                  className="btn btn-gradient-home effect btn-lg"
                  href={"/About"}
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End About Area */}
  </>
);
