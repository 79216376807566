import React from "react";
import AxiosServices from "./AxiosServices";

const Auth = new AxiosServices();

class ApplicationConfigServices{

    GetEmailConfigLists = async () => {
       
        return await Auth.get('/EmailConfig/GetEmailConfigLists');

      };

      UpdateEmails = async (ID, FromEmail, ToEmail) => {
        debugger
        return await Auth.get(`EmailConfig/UpdateEmails?id=${ID}&fromEmail=${FromEmail}&toMail=${ToEmail}`);
    };
    
}
export default ApplicationConfigServices
