import HeaderDashboard from "../DashBoard/HeaderDashboard";
import CareerServices from "../../../Services/CareerServices";

import Swal from "sweetalert2";
import React, { useState, useEffect, useReducer } from "react";
import { FaPencilAlt, FaTrashAlt, FaCloudDownloadAlt, FaEye, FaFilePdf, FaEnvelope, FaFileExcel ,FaArrowAltCircleLeft } from "react-icons/fa";


import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import RecruitmentServices from "../../../Services/RecruitmentServices";
import { saveAs } from "file-saver";
import ScrollToTop from "react-scroll-to-top";
import $ from "jquery";
import Preloader from "../../Preloader";
import Cookies from "universal-cookie";
import LoginView from "../Login/LoginView";
import H1B_Services from "../../../Services/H1B_Serivices";
import ApplyForm from "../../Client/Career/ApplyForm";
import EmailPreviewModal from "../../Admin/H1B/EmailPreviewModal"
import ExcelJS from 'exceljs';
import MUIDataTable from "mui-datatables";
import { Checkbox, Button } from '@mui/material';




var CareerServicesObj = new CareerServices();
var RecruitmentServicesObj = new RecruitmentServices();
var H1B_ServicesObj = new H1B_Services();

const H1B_Appliers = () => {
  // const[GridRender, SetGridRender] = useReducer(x => x+1, 0);
  const [Model, setModel] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    jobId: "",
    workId: "",
    location: "",
    designation: "",
    careerdetailId: "",
    workLocation: "",
    workLocationID: "",
    savedBy: "",
  });
  const [CareerDetails, setCareerDetail] = useState([]);
  const [H1BAppliersLists, setH1BAppliersLists] = useState([]);
  const cookies = new Cookies();
  const [Auth, SetAuth] = useState();
  const [flag, setFlag] = useState(true)
  const [show, setShow] = useState(false)
  const [showMailmodal, setShowMailModal] = useState(false)
  const [id, setId] = useState(false)
  const [firstName, setFirstName] = useState()
  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  const [toMail, setToMail] = useState();
  const [fromMail, setFromMail] = useState();
  const [CC, setCC] = useState();
  const [subject, setSubject] = useState();
  const [body, setBody] = useState();
  const htmlToText = require('html-to-text');
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); 
  const [selectedH1B,setSelectedH1B]=useState(2);
 

  useEffect(() => {
    SetAuth(cookies.get("userID"));
    CareerServicesObj.GetCareerAdmin().then((res) => {
      if (res.data != null) {
        setCareerDetail([...res.data]);
      }
      setTimeout(() => {
        $("#preloader").hide();
      }, 1000);
    });
  }, []);

  function download(url, filename) {

    RecruitmentServicesObj.Download(url, filename).then((response, status, xhr) => {
      var ext = url.split('.');
      const contentType = response.headers["content-type"];
      if (response.status == 200) {
        saveAs(new Blob([response.data], { type: contentType }), filename + '.' + ext[1]);
        Toast.fire({
          icon: "success",
          title: "Download Successfully",
        });
      } else {
        Toast.fire({
          icon: "error",
          title: "Failed to download !",
        });

      }
    });

  }
  const showPopup = (ApplierID, firstName) => {
    setId(ApplierID)
    setFirstName(firstName)
    setShow(true);
  }



  const [RecuritmentDetails, setRecuritmentDetail] = useState(null);
  useEffect(() => {
    SetAuth(cookies.get("userID"));

    H1B_ServicesObj.Get_H1B_Appliers_Lists(selectedYear,selectedMonth,selectedH1B)
      .then((res) => {
       
        if (res.data.length > 0) {

         
          const updatedData = res.data.map(item => {
            const date = new Date(item.appliedDate);
            const day = date.getDate();
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const monthIndex = date.getMonth();
            const year = date.getFullYear();
            const formattedDate = day + '-' + monthNames[monthIndex] + '-' + year;
    
            return { ...item, appliedDate: formattedDate };
        });
        console.log(updatedData,'applieddateformate')
        setH1BAppliersLists(updatedData)
        }
        else{
          
          setH1BAppliersLists([]);
        }

      })
      .catch((err) => console.log(err));
  }, [Render,selectedYear,selectedMonth,selectedH1B]);
  
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const exportPDF = () => {

    const pieChartElement = $("#Apply")[0];
    const content = pieChartElement.outerHTML;
    // pieChartElement.style.fontFamily = 'Calibri';
    pieChartElement.style.fontSize = '12px';
    kendo.drawing
      .drawDOM(pieChartElement, {
        paperSize: "A4",
        margin: { top: "1cm", left: "1cm", right: "1cm", bottom: "2cm" },
        scale: 0.8,
        pdf: {
          multiPage: true,
          fileName: `${firstName} H1B Deatils`,
          author: "Your Name",
          creator: "Your Application",
          subject: "PDF Subject",
          title: "PDF Title",
          keywords: "keyword1, keyword2",
        },
      })
      .then(function (group) {
        kendo.drawing.pdf.saveAs(group, `${firstName} H1B Deatils`);
      })
      .catch(function (error) {
        console.error("Error exporting PDF:", error);
      });

    $("#subpieBoxNoCount").css('margin-left', '');

    $(".subfileListNoCounts").hide();
    $(".main-loader").hide();
  };

  const handleCheckClick = (value, appliersid) => {
    H1B_ServicesObj.StatusUpdation(value, appliersid)
      .then((res) => {
        console.log(res.data)
        if (res.data > 0) {
          
          PageRender()
        }
      }
      )

  }
  const showMailPopup = (applierId, emailAddress, password, FirstName) => {
  
    H1B_ServicesObj.GetCradentialMailContent()
      .then((res) => {
        const H1blink = window.location.origin + "/H1BLoginView";

        if (res.data != null) {
          let plainTextBody = htmlToPlainText(res.data.body)
          plainTextBody = plainTextBody.replace("{UserCrendentials.H1b Applier Name}", FirstName);
          plainTextBody = plainTextBody.replace("{UserCrendentials.UserMail}", emailAddress);
          plainTextBody = plainTextBody.replace("{UserCrendentials.Password}", password);
          plainTextBody=plainTextBody.replace("{UserCrendentials.H1B Login Link}",H1blink)
          
          setFromMail(res.data.from)
          setCC(res.data.cc)
          let plainTextSubject = htmlToPlainText(res.data.subject)
          plainTextSubject = plainTextSubject.replace("{UserCrendentials.H1b Applier Name}", FirstName);
          plainTextSubject = plainTextSubject.replace("{UserCrendentials.UserMail}", emailAddress);
          plainTextSubject = plainTextSubject.replace("{UserCrendentials.Password}", password);
          setSubject(plainTextSubject)
          setBody(plainTextBody)
          setId(applierId)
          setToMail(emailAddress)
          setShowMailModal(true);
        }

      })

  }
  function htmlToPlainText(html) {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;

    let plainText = '';


    for (let node of tempElement.childNodes) {
      if (node.nodeType === Node.TEXT_NODE) {

        plainText += node.textContent.replace(/\?/g, '');
      } else if (node.nodeType === Node.ELEMENT_NODE) {

        plainText += node.textContent.replace(/\?/g, '') + '\n';
      }
    }

    return plainText;
  }
 
  function formatDate(dateString) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const [day, month, year] = dateString.split('-');
    const monthName = months[parseInt(month) - 1];
    return `${day}-${monthName}-${year}`;
  }
  const exportToExcel = () => {
    
    const tableData = H1BAppliersLists.map((v) => ({
      
      h1BCode: v.h1BCode,
      firstName: v.firstName,
      middleName:v.middleName,
      lastName:v.lastName,
      emailAddress: v.emailAddress,
      passportnumber: v.passportnumber,
      country:v.countryName,
      dOB:formatDate(v.dob),
      gender:v.gender,
      contactNumber: v.contactNumber,
      whatsAppNumber:v.whatsAppNumber,
      address:v.completeAddress,
      jobTittle:v.jobRoletitle,
      skills:v.primarySkills,
      totalExprience: v.totalExprience,
      studiedInUSA:v.eligibleForH1BMaster==1?'Yes':'No',
      appliedDate: v.appliedDate,
      h1BStatus: v.h1BStatus,
      questions:v.anyQuestions
    }));
    const xls = new ExcelJS.Workbook();
    const sheet = xls.addWorksheet('H1B Applicants');

    const boldFontStyle = { bold: true };
    const headers = ['H1B Code', 'First Name','Middle Name','Last Name', 'Email', 'Passport Number','Country','DOB', 'Gender', 'Mobile Number','WhatsApp Number','Address','Job tittle','Skills', 'Experience','studied in USA', 'Applied Date','Clarification Questions', 'H1B Status'];
    sheet.addRow(headers).font = boldFontStyle;
    sheet.getRow(1).alignment = { horizontal: 'center' }; // Align headings to center

    sheet.columns.forEach(column => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.toString().length : 20;
          if (columnLength > maxLength) {
              maxLength = columnLength;
          }
      });
      column.width = maxLength < 20 ? 20 : maxLength + 2;
  });
  sheet.eachRow(row => {
    row.eachCell(cell => {
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.width = 30; // You can adjust this width as needed
      cell.height = 40; // You can adjust this height as needed
    });
  });
    // Add data rows
    tableData.forEach((rowData) => {
      
      const row = sheet.addRow([
        rowData.h1BCode,
        rowData.firstName,
        rowData.middleName,
        rowData.lastName,
        rowData.emailAddress,
        rowData.passportnumber,
        rowData.country,
        rowData.dOB,
        rowData.gender ==0?'Male':'Female',
        rowData.contactNumber,
        rowData.whatsAppNumber,
        rowData.address,
        rowData.jobTittle,
        rowData.skills,
        rowData.totalExprience,
        rowData.studiedInUSA,
        rowData.appliedDate,
        rowData.questions,
        rowData.h1BStatus === 1 ? 'Approved' : 'OnProcess'
      ]);
      row.alignment = { vertical: 'middle', horizontal: 'center' };
      const rowIndex = row.number;
      const statusCell = sheet.getCell(`S${rowIndex}`);

      if (rowData.h1BStatus === 1) {
        statusCell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00FF00' } // Green color for Approved
        };
      }
      //  else {
      //   statusCell.fill = {
      //     type: 'pattern',
      //     pattern: 'solid',
      //     fgColor: { argb: 'FFFFFF' } // White color for OnProcess
      //   };
      // }
    });

    xls.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'H1B_Applicants.xlsx');
    });
};


  const handleYearChange = (event) => {

    setSelectedYear((event.target.value));
};
const years = [];
const currentYear = new Date().getFullYear();
for (let year = currentYear-3; year <= currentYear + 1; year++) {
    years.push(year);
}
const handleMonthChange = (event) => {
  
  setSelectedMonth(parseInt(event.target.value));


};
const months = [
  {value:0,label:'All'},
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
];

const handleSelectedH1bClick = (value) => {
  
    setSelectedH1B(value)

}

const columns = [
  {
    name: "h1BCode",
      label: "H1B Code",
      options: {
        filter: true,
        sort: true,
      },
  },
  {
    name: "firstName",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
  },
  {
    name: "emailAddress",
      label: "Email Address",
      options: {
        filter: true,
        sort: true,
      },
  },
  {
    name: "contactNumber",
      label: "Mobile Number",
      options: {
        filter: true,
        sort: true,
      },
  },
  {
    name: "totalExprience",
      label: "Experience",
      options: {
        filter: true,
        sort: true,
      },
  },
  {
    name: "passportnumber",
      label: "Passport Number",
      options: {
        filter: true,
        sort: true,
      },
  },
  {
    name: "appliedDate",
      label: "AppliedDate",
      options: {
        filter: true,
        sort: true,
      },
  },
  {
    name: "h1BStatus",
    label: "H1B Status",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta) => {
        ;
        const rowData =H1BAppliersLists[tableMeta.rowIndex];
        
        return (
          <div className="d-flex">
            <Checkbox
             style={{transform: 'scale(0.8)'}}
              checked={rowData.h1BStatus === 1}
              onChange={(e) => handleCheckClick(e.target.checked ? 1 : 2, rowData.applierId)}
            />
            {rowData.h1BStatus === 1 && (
              <Button
                variant="outlined"
                onClick={() => showMailPopup(rowData.applierId, rowData.emailAddress, rowData.password, rowData.firstName)}
                title="Email Preview and Shoot"
              >
                <FaEnvelope />
              </Button>
            )}
          </div>
        );
      }
    },
  },
  {
    name: "",
    label: "Actions",
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        const rowData = H1BAppliersLists[tableMeta.rowIndex];
        return (
          <div className="form-button-action tableoptions">
            <a
              className="ms-2"
              id="a1"
              onClick={() => download(rowData.passportCopy, rowData.firstName + " PassportCopy")}
              title="Download Passport"
            >
              <FaCloudDownloadAlt />
            </a>
            <a
              className="ms-2"
              id="a2"
              onClick={() => download(rowData.resumeCopy, rowData.firstName + " ResumeCopy")}
              title="Download Resume"
            >
              <FaCloudDownloadAlt />
            </a>
            <a
              id="a3"
              className="ms-2"
              onClick={() => showPopup(rowData.applierId, rowData.firstName)}
              title="View Details"
            >
              <FaEye />
            </a>
          </div>
        );
      },
    },
  },
]
  return Auth ? (
    <>
      <Preloader />
      <HeaderDashboard />
      {/* <div className="col-md-12 text-end">
      <a title="Go Back">
        <FaArrowAltCircleLeft />
      </a>
    </div> */}
      <div class="main-panel">
        <div class="content">
          <div class="row">
            <div class="col-md-12">
              <div class="card grid">
                <Tabs>
                  <TabList>
                    <Tab style={{ width: '100%' }}>H1B Appliers List</Tab>
                  </TabList>
                  {/* tab-1 ---> Jobs */}
                  <TabPanel>
                    <div className="page-inner">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="">
                            <div class="card-header">
                              <div className="row">
                                <div className="d-flex align-items-center col-md-2">
                                  <h4 className="card-title">H1B Lists </h4>
                                 
                                 
                                  
                                </div>
                                <div className="col-md-3">
                                  <select id="year" className="form-select" value={selectedYear} onChange={handleYearChange}>
                                  <option value="All">All</option>
                                        {years.map((year) => (
                                          
                                            <option key={year} value={year}>{year}</option>
                                        ))}
                                    </select>
                               </div>
                                  <div  className="col-md-3">
                                  <select id="month" className="form-select" value={selectedMonth} onChange={handleMonthChange}>
                                        {months.map((month) => (
                                            <option key={month.value} value={month.value}>{month.label}</option>
                                        ))}
                                    </select>
                                  </div>
                                  <div className="col-md-3 mt-1">
                                    <label>Selected Candidate</label>
       
                                  <Checkbox className="ms-1"
                                   style={{transform: 'scale(0.8)'}}
                                                type="checkbox"
                                                onChange={(e) => handleSelectedH1bClick(e.target.checked ? 1 : 2)}
                                                />
                                  </div>
                                  
                                <div  className="col-md-1 text-end">
                                  <a onClick={exportToExcel}
                                  title="Download Excel" >
                                    <FaFileExcel />
                                  </a>
                                </div>
                              </div>


                            </div>
                            <div class="card-body">
                              <div class="table-responsive">
                              <MUIDataTable
                          data={H1BAppliersLists}
                        
                          columns={columns}
                          options={{
                            responsive: true,
                            download: false,
                            print: false,
                            selectableRows: false,
                            filter: true,
                            viewColumns: true,
                            rowsPerPageOptions: [
                              10,
                              25,
                              100,
                             
                            ],
                            rowsPerPage: 10
                           
                          }}
                        />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {show && <div
                      class="modal fade show"
                      id="addRowModal"
                      tabindex="-1"
                      role="dialog"
                      aria-hidden="true"
                      data-bs-backdrop="static"
                      style={{ display: "block", overflowY: "scroll", background: 'rgba(0,0,0,.7)' }}
                    >
                      <div class="modal-dialog modal-xl" role="document">
                        <div class="modal-content">
                          <div class="modal-header no-bd">
                            <h5 class="modal-title">
                              <span class="fw-mediumbold">View Details</span>
                            </h5>

                            <button

                              type="button"
                              class="close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => setShow(false)}

                            >

                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body" >
                            <div className="text-end mr-5">
                              <a
                                className="text-end"
                                id="a3"
                                onClick={() => exportPDF()}
                                title="View Details"
                                style={{
                                  cursor: 'pointer'
                                }}
                              >
                                <FaFilePdf style={{ width: '24px', height: '24px' }} />
                              </a>
                            </div>

                            <ApplyForm applierId={id} FirstName={firstName} flag={flag} />
                          </div>
                        </div>
                      </div>
                    </div>}
                    {showMailmodal && <div
                      className="modal fade show"
                      id="addRowModal1"
                      tabindex="-1"
                      role="dialog"
                      aria-hidden="true"
                      data-bs-backdrop="static"
                      style={{ display: "block", overflowY: "scroll", background: 'rgba(0,0,0,.7)' }}
                    >
                      <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                          <div class="modal-header no-bd">
                            <h5 class="modal-title">
                              <span class="fw-mediumbold">Email Preview</span>
                            </h5>

                            <button

                              type="button"
                              class="close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() => setShowMailModal(false)}
                            >

                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body" >
                            <div className="text-end mr-5">

                            </div>
                            <EmailPreviewModal applierId={id} FromMail={fromMail} ToMail={toMail} CC={CC} Subject={subject} Body={body} />
                          </div>
                        </div>
                      </div>
                    </div>}

                  </TabPanel>


                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="bg-dark text-light">
        {/* Start Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            <div className="footer-bottom-box">
              <div className="row">
                <div className="col-lg-12">
                  <p>
                    © Copyright 2012 - {new Date().getFullYear()}. All Rights
                    Reserved by{" "}
                    <a href="/Dashboardview"> Vaanam Technologies Pvt Ltd.</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollToTop smooth color="#2b5690" />
        {/* End Footer Bottom */}
      </footer>
    </>
  ) : (
    <LoginView />
  );
};

export default H1B_Appliers;
