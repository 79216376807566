import HeaderDashboard from "../DashBoard/HeaderDashboard";
import CareerServices from "../../../Services/CareerServices";
import Swal from "sweetalert2";
import React, { useState, useEffect, useReducer } from "react";
import { FaPencilAlt, FaTrashAlt, FaCloudDownloadAlt } from "react-icons/fa";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import RecruitmentServices from "../../../Services/RecruitmentServices";
import { saveAs } from "file-saver";
import ScrollToTop from "react-scroll-to-top";
import $ from "jquery";
import Preloader from "../../Preloader";
import H1BConfig from "../ApplicationConfiguration/H1BConfig"
import CareerConfig from "./CareerConfig";
import LoginConfig from "./LoginConfig";
import ApplicationConfigServices from "../../../Services/ApplicationConfigServices";
import EnquiryConfig from "./EnquiryConfig";
import LoginView from "../Login/LoginView";
import Cookies from "universal-cookie";




const ApplicationConfigServicesObj = new ApplicationConfigServices();


const ApplicationConfig = () => {
  const [H1BList,SetH1bList]=useState([]);
  const [careerList,setCareerList]=useState([]);
  const[requiementList,setRequiementList]=useState([]);
  const [loginList,setLoginList]=useState([]);
  const [allLists,setAllLists]=useState([]);
  const [Auth, SetAuth] = useState();
  const cookies = new Cookies();
  useEffect (() => {

    SetAuth(cookies.get("userID"));
    getConfigLists()
},[])

const getConfigLists = () => {
  ApplicationConfigServicesObj.GetEmailConfigLists()
  .then((res) => {
    setAllLists(res.data)
   console.log(res.data)
   setTimeout(() => {
    $("#preloader").hide();
  }, 1000);
  })  
};


    return Auth ? (
     
        <>
         <Preloader/>
         <HeaderDashboard />
         <div class="main-panel">
        <div class="content">
          <div class="row">
            <div class="col-md-12">
              <div class="card grid">
                <Tabs>
                  <TabList>
                    <Tab style={{ width: '25%' }} >H1B Apply Form </Tab>
                    <Tab style={{ width: '25%' }} >Career</Tab>
                    <Tab style={{ width: '25%' }} >Enquiry</Tab>
                    <Tab style={{ width: '25%' }} >H1B User Credentials</Tab>
                  </TabList>

                  {/* tab-1 ---> Jobs */}
                  <TabPanel>
                    <div className="page-inner">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="">
                            <div class="card-header">
                              <div class="d-flex align-items-center">
                                <h4 class="card-title">H1B Apply Form</h4>
                               
                              </div>
                            </div>
                            <div class="card-body">
                              <div class="table-responsive">
                                <table
                                  className="display table table-striped table-hover"
                                  id="AssociateDisplay"
                                >
                               <tbody>
                              {allLists.length>0 &&
                                  <H1BConfig H1BList={allLists} getConfigLists={getConfigLists}/>
                              }                                
                                
                               </tbody>
                                 
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </TabPanel>

                  {/* tab-2 --> Resumes */}
                  <TabPanel>
                    <div className="page-inner">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="">
                            <div class="card-header">
                              <div class="d-flex align-items-center">
                                <h4 class="card-title">Careers </h4>
                              </div>
                            </div>
                            <div class="card-body">
                              <div class="table-responsive">
                                <table
                                  className="display table table-striped table-hover"
                                  id="AssociateDisplay"
                                >
                                 
                                  <tbody>
                                  {allLists.length>0 &&
                                  <CareerConfig H1BList={allLists} getConfigLists={getConfigLists}/>

                              }   
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                   
                  </TabPanel>


                  <TabPanel>
                    <div className="page-inner">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="">
                            <div class="card-header">
                              <div class="d-flex align-items-center">
                                <h4 class="card-title">Enquirys</h4>
                              </div>
                            </div>
                            <div class="card-body">
                              <div class="table-responsive">
                                <table
                                  className="display table table-striped table-hover"
                                  id="AssociateDisplay"
                                >
                                 
                                  <tbody>
                                  {allLists.length>0 &&
                                  <EnquiryConfig H1BList={allLists} getConfigLists={getConfigLists}/>

                              }   
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                   
                  </TabPanel>
                  <TabPanel>
                    <div className="page-inner">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="">
                            <div class="card-header">
                              <div class="d-flex align-items-center">
                                <h4 class="card-title"> From Mail For H1b Candidates Login </h4>
                              </div>
                            </div>
                            <div class="card-body">
                              <div class="table-responsive">
                                <table
                                  className="display table table-striped table-hover"
                                  id="AssociateDisplay"
                                >
                                  <tbody>
                                  {allLists.length>0 &&
                                  <LoginConfig H1BList={allLists} getConfigLists={getConfigLists}/>

                              }   
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                   
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="bg-dark text-light">
        {/* Start Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            <div className="footer-bottom-box">
              <div className="row">
                <div className="col-lg-12">
                  <p>
                    © Copyright 2012 - {new Date().getFullYear()}. All Rights
                    Reserved by{" "}
                    <a href="/Dashboardview"> Vaanam Technologies Pvt Ltd.</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollToTop smooth color="#2b5690" />
        {/* End Footer Bottom */}
      </footer>
        </>
       

    ) : (
        <LoginView />
      );
}

export default ApplicationConfig;