import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import vaanam from "../../../Assets/img/Vaanam.png";
import loginImage from "../../../Assets/img/login.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import H1BLoginServices from "../../../Services/H1BloginServices";
import Cookies from "universal-cookie";
import Preloader from "../../Preloader";
const AuthObject = new H1BLoginServices();
export default function H1BLoginView() {
  const navigate= useNavigate()
    const [passwordtoggle, setPasswordToggle] = useState(true);
    const cookies = new Cookies();
    const [disabled, setDisabled] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const passwordToggle = () => {
        setPasswordToggle(!passwordtoggle);
       
      };
      const [dummy, setDummy] = useState({
        prefixName: "",
        endValue: "",
    
        errUserName: "",
        errPassword: "",
      });
      const onKeyDown = (event) => {
        if (event.code === "Space") event.preventDefault();
      };
      const validateUser = (e) => {
        var myerror = dummy;
        if (e.target.value == null || e.target.value == "") {
          myerror.errUserName = "Username is Required";
        } else {
          myerror.errUserName = "";
        }
        setDummy({ ...myerror });
      };
      const validatePassword = (e) => {
        var myerror = dummy;
        if (e.target.value == null || e.target.value == "") {
          myerror.errPassword = "Password is Required";
        } else {
          myerror.errPassword = "";
        }
        setDummy({ ...myerror });
      };
      useEffect(() => {
        setTimeout(() => {
          $("#preloader").hide();
        }, 1000);
      }, []);

      const loginSubmit = (e) => {
        e.preventDefault();
        var myerror = dummy;
        
        var flag = true;
        const obj = new FormData(e.currentTarget);
        var data = {
            H1bcandidateUsername: e.currentTarget.UserName.value,
            H1bcandidatePassword: e.currentTarget.Password.value,
    
          rememberMeflag: e.currentTarget.Remember.value,
        };
        if (data.H1bcandidateUsername == null || data.H1bcandidateUsername == "") {
          myerror.errUserName = "Username is Required";
          flag = false;
        } else {
          myerror.errUserName = "";
        }
        if (data.H1bcandidatePassword == null || data.H1bcandidatePassword == "") {
          myerror.errPassword = "Password is Required";
          flag = false;
        } else {
          myerror.errPassword = "";
        }
        setDummy({ ...myerror });
    
        if (flag == true) {
          login(data);
        }
      };

      const login = (authentication) => {
        
       
        setIsLoaded(true);
          AuthObject.getData(authentication)
          .then((res) => {
         
            setTimeout(() => {
              if(res.data[0].h1BcandidateId>0){
                RedirectFunction(res.data[0].h1BcandidateId);
              }
               
              }, 2000),
              setDisabled(false)
              
          })
          .catch((res) => {
            setIsLoaded(false);
            swal({
              text: "Please Enter valid User Name or Password",
              icon: "warning",
            });
    
            // cookies.remove("KAPPU");
            // cookies.remove("KAPPP");
            setDisabled(false);
          });
    
        function RedirectFunction(Id) {
          setIsLoaded(false);
          navigate("/SelectedH1BCandidate",{state:{H1bCandiateId:Id}})
          // window.location.href = "/SelectedH1BCandidate";
          ////$('.main-loader').hide();
        }
    }
     return(
        <>
      <Preloader />
      <div className="login-area bg-gray" style={{ height: "713px" }}>
        <div className="container">
          <div className="row"></div>
            <div className="col-lg-8 offset-lg-2">
              <form className="white-popup-block" onSubmit={loginSubmit}>
                <div className="row align-center">
                  <div className="col-lg-5 login-social">
                    <img src={vaanam} alt="Logo" style={{ height: "60px" }} />{" "}
                    <br></br>
                    <img
                      src={loginImage}
                      alt="Login"
                      style={{ height: "263px" }}
                    />
                  </div>
                  <div className="col-md-7 login-custom">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="form-group">
                          <label>
                            <h6>Enter Username</h6>
                          </label>
                          <input
                            type="text"
                            name="UserName"
                            maxLength={50}
                            className="form-control"
                            placeholder="Username"
                            onChange={(e) => validateUser(e)}
                           
                           
                       
                          />{" "}
                          <span className="text-danger">
                            {dummy.errUserName}
                          </span>
                          
                        </div>
                      
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="form-group">
                          <label>
                            <h6>Enter Password</h6>
                          </label>
                          <input
                            type={passwordtoggle ? "password" : "text"}
                            className="form-control"
                            placeholder="Password"
                            maxLength={50}
                            name="Password"
                            onKeyDown={(event) => onKeyDown(event)}
                            onChange={(e) => validatePassword(e)}
                          />
                         <span className="text-danger">
                            {dummy.errPassword}
                          </span>
                          <span className="password-icon">
                            <span onClick={() => passwordToggle()}>
                              {passwordtoggle ? <FaEyeSlash /> : <FaEye />}
                            </span>
                          </span>
                        </div>
                        <p className="error" style={{ color: "red" }}>
                          {/* {formErrors.password} */}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="row">
                        <label htmlFor="login-remember">
                          <input
                            type="checkbox"
                            id="Remember"
                            name="Remember"
                           
                          />
                          Remember Me
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="row">
                        {!isLoaded ? (
                          <button type="submit">Login</button>
                        ) : (
                          <button
                            className="btn btn-primary"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Loading...
                          </button>
                        )}
                      </div>
                     </div>
                    <div>
                      <a
                        title="Lost your password"
                        href="/H1BForgetView"
                        className="lost-pass-link"
                      >
                        Lost your password?
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            </div>
            </div>
            
         
  
        </>
    )

}