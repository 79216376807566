import React, { useEffect, useState } from "react";
import vaanamlogolight from "../../../Assets/img/Vaanam-light.png";
import vaanamlogo from "../../../Assets/img/Vaanam.png";
import $ from "jquery";
import Preloader from "../../Preloader";
import H1BLoginview from "../H1BLogin/H1BLoginView"
import { useNavigate } from "react-router-dom";


export const Header = (props) => {
  const navigate = useNavigate()
  const id = props?.id
  const DropdownView = (val) => {
    var ClassVal = "." + val;
    $(ClassVal).css("display", "block");
  };

  const DropdownLeave = (val) => {
    var ClassVal = "." + val;
    $(ClassVal).css("display", "none");
  };

  const [isScrolled, setIsScrolled] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [showMailmodal, setShowMailModal] = useState(false)


  useEffect(() => {
    scrollToTop();
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    if (window.scrollY > 10) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const showMailPopup = () => {
    setShowMailModal(true);
  }
  return (
    <>
      <Preloader />
      {/* <div class="top-bar-area inc-pad bg-darks text-light">
        <div class="container-full">
          <div class="row align-center">
            <div class="col-lg-6 info"></div>
            <div class="col-lg-6 text-right item-flex">
              <div class="info">
                <ul className="infoul">
                  <li>
                    <a className="topbar" href="/Career">
                      Careers
                    </a>
                  </li>
                  <li>
                    <a className="topbar" href="/NewsEvents">
                      News
                    </a>
                  </li>
                </ul>
              </div>
              <div class="social">
                <ul className="infoul">
                  <li>
                    <a
                      className="topbar"
                      href="https://www.facebook.com/Vaanam-Technologies-Pvt-Ltd-506680116025192/"
                    >
                      <i class="fab fa-facebook-f"></i>
                    </a>
                  </li>

                  <li>
                    <a
                      className="topbar"
                      href="https://www.linkedin.com/company/vaanam-technologies-pvt-ltd/"
                    >
                      <i class="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* Header 
      ============================================= */}
      <header id="home">
        {/* Start Navigation */}
        <div class="wrap-sticky" style={{ height: "90.6667px" }}>
          <nav
            className={`navbar navbar-default inc-top-bar navbar-fixed white bootsnav on menu-center no-full ${isScrolled ? "sticked" : ""
              }`}
          >
            <div className="container-full">
              {/* Start Atribute Navigation */}
              {/* <div className="attr-nav">
              <div className="call">
                <div className="icon">
                  <i className="fas fa-phone" />
                </div>
                <div className="info">
                  <span>Have any question?</span>
                  <h5>+91 422 256 7626</h5>
                </div>
              </div>
            </div> */}
              {/* End Atribute Navigation */}
              {/* Start Header Navigation */}
              <div className="navbar-header">
                <button
                  type="button"
                  className="navbar-toggle"
                  data-toggle="collapse"
                  data-target="#navbar-menu"
                >
                  <i className="fa fa-bars" />
                </button>
                <a href="/" className="navbar-brand">
                  <img
                    src={vaanamlogolight}
                    className="logo logo-display"
                    alt="Logo"
                  />
                  <img
                    src={vaanamlogo}
                    className="logo logo-scrolled"
                    alt="Logo"
                  />
                </a>
              </div>
              {/* End Header Navigation */}
              {/* Collect the nav links, forms, and other content for toggling */}
              <div className="collapse navbar-collapse" id="navbar-menu">
                <ul
                  className="nav navbar-nav navbar-center"
                  data-in="#"
                  data-out="#"
                >
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li
                    className="dropdown"
                  >
                    <a
                      href="/ServiceHome"
                      className="dropdown-toggle active"
                      data-toggle="dropdown"
                    >
                      Services
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a href="/Enterprise">Enterprise Application</a>
                      </li>
                      <li>
                        <a href="/MicrosoftServices">Microsoft Services</a>
                      </li>
                      <li>
                        <a href="/AIML" style={{ textTransform: "initial" }}>
                          AI and ML Services
                        </a>
                      </li>
                      <li>
                        <a href="/Mobility">Mobility Services</a>
                      </li>
                      <li>
                        <a href="/BusinessIntellegence">
                          Business Intelligence
                        </a>
                      </li>
                      <li>
                        <a href="/Reality">Reality Technologies</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="/OurProducts">Our Products</a>
                  </li>
                  <li>
                    <a href="/OurClients">Our Clients</a>
                  </li>
                  {/* <li
                  className="dropdown "
                  onMouseEnter={() => DropdownView("ddc")}
                  ontouchstart={() => DropdownView("ddc")}
                  onMouseLeave={() => DropdownLeave("ddc")}
                >
                  <a
                    href="#"
                    className="dropdown-toggle active"
                    data-toggle="dropdown"
                  >
                    Corporate
                  </a>
                  <ul className="dropdown-menu ddc">
                    <li>
                      <a href="/About">About Us</a>
                    </li>
                    <li>
                      <a href="/Contact">Contact Us</a>
                    </li>
                  </ul>
                </li> */}

                  <li>
                    <a href="/About">About Us</a>
                  </li>
                  <li>
                    <a href="/Career">Careers</a>
                  </li>
                  <li>
                    <a href="/Contact">Contact Us</a>
                  </li>
                  
                  { id && id > 0 && (
  <li
    className="dropdown profiledd"
    onMouseEnter={() => DropdownView("ddl")}
    ontouchstart={() => DropdownView("ddl")}
    onMouseLeave={() => DropdownLeave("ddl")}
  >
    <a href="#" className="active" data-toggle="dropdown">
      <div className="profilename">
        <i className="fas fa-user" />
        &nbsp;
        <label className="usernameicon">
          {/* {cookies.get("userName")} */}
        </label>
      </div>
    </a>
    <ul className="dropdown-menu ddl">
      <li>
        <a href="/H1BLoginView">
          <i className="fas fa-sign-out-alt"></i> H1B Logout
        </a>
        <a href="/H1BChangePassword" onClick={() => navigate("/H1BChangePassword", { state: { H1bCandiateId: id } })}>
          <i className="fa fa-key" aria-hidden="true"></i> Change Password
        </a>
      </li>
    </ul>
  </li>
)}

                  <li className="showlist">
                    <a href="/Career">Careers</a>
                  </li>
                  <li className="showlist">
                    <a href="/NewsEvents">News</a>
                  </li>
                </ul>
              </div>
              {/* /.navbar-collapse */}
            </div>
          </nav>
        </div>

        {/* End Navigation */}
      </header>
      {/* End Header */}


    </>
  );
};
