import React, { useState, useEffect } from "react";
import "jquery";
import $ from "jquery";
import Swal from "sweetalert2";
import RecruitmentServices from "../../../Services/RecruitmentServices";

var RecruitmentServicesObj = new RecruitmentServices();
const Apply = (props) => {
   debugger
  const [RecruitmentId, setRecruitmentId] = useState("");
  const [resume, setResume] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setformError] = useState({
    name: "",
    email: "",
    phone: "",
    experience: "",
    resumeUrl: "",
  });
  const CloseModel=()=>{

    document.getElementById("Apply").reset();

    setformError({
      name: "",
      email: "",
      phone: "",
      experience: "",
      resumeUrl: "",

    });
  }
  const [user, setUser] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    experience: "",
    resumeUrl: "",
    careerdetailId: "",
  });
  

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 750,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };

  function numberOnly(e) {
    var element = document.getElementById(e);
    element.value = element.value.replace(/[^\d.]/g, ''); 

  }

  const name = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.name = "Name is Required";
    } else {
      myerror.name = "";
    }
    setformError({ ...myerror });
  };

  const phone = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.phone = "Phone Number is Required";
    } else {
      myerror.phone = "";
    }
    setformError({ ...myerror });
    numberOnly("phoneNumber");
  };

  // const experience = (e) => {
  //   var myerror = error;
  //   if (e.target.value == null || e.target.value == "") {
  //     myerror.experience = "Experience is Required";
  //   } else {
  //     myerror.experience = "";
  //   }
  //   setformError({ ...myerror });
  //   numberOnly("experience");
  // };

  const email = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.email = "Email is Required";
    } 
    else if ( !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value) ) {
      console.log(e.target.value)
      myerror.email = "Please Enter a Valid Email Address";

    }else {
      myerror.email = "";
    }

    setformError({ ...myerror });
  };
  const resumechange = (e) => {
    var myerror = error;
    if (e.target.value == null || e.target.value == "") {
      myerror.resumeUrl = "Resume is Required";
      setResume("");
    } else {
      setResume(e.target.files[0]);
      myerror.resumeUrl = "";
    }
    setformError({ ...myerror });
  };

  var uploadresume = { file: resume };
  const [progress, setProgress] = useState();

  const onSubmit = (res) => {
    
   
    res.preventDefault();

    var myerror = error;
 

    var flag = true;

    var data = {
      careerdetailId: props.Model.careerdetailId,
      Name: document.getElementById("name").value,
      Email: document.getElementById("email").value,
      PhoneNumber: document.getElementById("phoneNumber").value,
      ResumePath: null,
      Experience: document.getElementById("experience").value,
      workLocation:props.Model.workLocation??"",
       jobTittle:props.Model.jobTitle
    };
    console.log(data);
    if (data.Name == null || data.Name == "") {
      myerror.name = "Name is Required";
      flag = false;
    } else {
      myerror.name = "";
    }

    if (data.Email == null || data.Email == "") {
      myerror.email = "Email is Required";
      flag = false;
    } else if ( !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.Email) ) {
      console.log(data.Email)
      myerror.email = "Please Enter a Valid Email Address";
      flag=false;
    } else {
      myerror.email = " ";

    }

    if (data.PhoneNumber == null || data.PhoneNumber == "") {
      myerror.phone = "Phone Number is Required";
      flag = false;
     
    } else if (data.PhoneNumber.length <= 9){
      console.log(data.PhoneNumber.length)
      myerror.phone = "Must Ten-Digit";
      flag=false;
    }else{
      myerror.phone = "";
    }

    // if (data.Experience == null || data.Experience == "") {
    //   myerror.experience = "Experience is Required";
    //   flag = false;
    // } else {
    //   myerror.experience = "";
    // }

    if (uploadresume.file == null || uploadresume.file == "") {
      myerror.resumeUrl = "Resume is Required";
      flag = false;
    } else {
      myerror.resumeUrl = "";
    }
    setformError({ ...myerror });
    if (flag == true) {
      const formData = new FormData();

      $.each($("#resume")[0].files, function (i, file) {
        formData.append("Resume", file);
      });
      RecruitmentServicesObj.UploadResume(formData).then((res) => {
        data.ResumePath = res.data;
        RecruitmentServicesObj.Create(data).then((res) => {
          if (res.data == 1) {
            $(".close").trigger("click");
            Toast.fire({
              icon: "success",
              title: "Applied Successfully",
            });
            setTimeout(function () {
              window.location.reload();
            }, 750);
          } else {
            Toast.fire({
              icon: "error",
              title: "Failed to Apply!",
            });
          }
        });
      });
      setIsLoaded(true);
    }
  };

  return (
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header no-bd">
          <h5 class="modal-title">
            <span class="fw-mediumbold">Apply Job</span>
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
            data-dismiss="modal"
            onClick={() => {CloseModel()}}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <form  onSubmit={onSubmit} id="Apply" autoComplete="Off">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group  ">
                    <label>
                      Name<span className="text-danger">*</span>
                    </label>

                    <input
                      type="text"
                      id="name"
                      className="form-control"
                      placeholder="Name"
                      name="name"
                      onChange={name}
                    />
                    <span className="text-danger">{error.name}</span>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group  ">
                    <label>
                      Email<span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      placeholder="Email"
                      name="name"
                      onChange={email}
                    />
                    <span className="text-danger">{error.email}</span>
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="form-group ">
                    <label>
                      Phone Number<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="phoneNumber"
                      className="form-control"
                      placeholder="Phone Number"
                      name="name"
                      // pattern="[1-9]{1}[0-9]{9}"
                      maxLength={10}
                      onChange={phone}
                    />
                    <span className="text-danger">{error.phone}</span>
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="form-group ">
                    <label>Experience</label>

                    <input
                      type="text"
                      id="experience"
                      className="form-control"
                      maxLength={5}
                      defaultValue={0}
                      placeholder="Experience"
                      name="experience"
                      step="00.01"
                      onChange={()=>numberOnly("experience")}
                    />
                    <span className="text-danger">{error.experience}</span>
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="form-group ">
                    <label>
                      Upload Resume <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      id="resume"
                      type="file"
                      className="form-control"
                      name="resume"
                      onChange={resumechange}
                    />
                    <span className="text-danger">{error.resumeUrl}</span>
                  </div>
                </div>
              </div>

              <div className="modal-footer no-bd">
                {!isLoaded ? (
                  <button type="submit" id="apply" className="btn btn-primary">
                    Apply
                  </button>
                ) : (
                  <button class="btn btn-primary" type="button" disabled>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </button>
                )}

                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  onClick={() => {CloseModel()}}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Apply;
