import H1B_Services from "../../../Services/H1B_Serivices";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import $ from "jquery";
import { useLocation, useNavigate } from "react-router-dom";
import H1B_Appliers from "./H1B_Appliers";
const H1B_ServicesObj = new H1B_Services();


export default function EmailPreviewModal(props) {
    
    const ID = props?.applierId;
    const ToMail=props?.ToMail;
    const Subject=props?.Subject;
    const FromMail=props?.FromMail ;
    const CC=props?.CC
    const Body=props?. Body.trim()
    const [isLoaded, setIsLoaded] = useState(false);

    const SendMail = (e) => {
        e.preventDefault();
        
        
        const mailData = {
            
            toEmail: ToMail,
            subject: Subject,
            from: FromMail,
            cC: CC,
            body: Body
        };
            H1B_ServicesObj.SendPreviewMail(mailData)
            .then((res) => {
                console.log(res.data)
                
                if (res && res.data == 1) {
                    Toast.fire({
                        icon: "success",
                        title: "Email Sent Successfully",
                    });
                    setTimeout(function () {
                        //window.location.reload();
                         window.location.href="/H1B_Appliers"
                        
                    }, 2000);
                } 
                else {
                    
                    Toast.fire({
                        icon: "error",
                        title: "Email didn't sent ,something went wrong",
                    });
                    setTimeout(function () {
                        //window.location.reload();
                         window.location.href="/H1B_Appliers"
                        
                    }, 2000);                }
            })
            .catch((error) => {
                console.error('Error sending preview mail:', error);
            });
        
         setIsLoaded(true);
    }
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    return (
        <>
            <div>

                <form  onSubmit={SendMail}>



                    <div className="form-horizontal" id="styleID">
                    <div className="form-group">
                            <label className="" htmlFor="ToEmail">
                                From
                            </label>
                            <div className="col-md-9">
                                <input
                                    className="form-control   "
                                    id="FromEmail"
                                    name="FromEmail"
                                    readOnly="readonly"
                                    type="text"
                                    value={FromMail}
                                    disabled

                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="" htmlFor="ToEmail">
                                To
                            </label>
                            <div className="col-md-9">
                                <input
                                    className="form-control   "
                                    id="ToEmail"
                                    name="ToEmail"
                                    readOnly="readonly"
                                    type="text"
                                    value={ToMail}
                                    disabled
                                />
                            </div>
                        </div>
                        {/* <div className="form-group">
                            <label className="" htmlFor="CCEmail">
                                CC
                            </label>
                            <div className="col-md-9">
                                <input
                                    className="form-control "
                                    id="CCEmail"
                                    name="CCEmail"
                                    placeholder="No CC mails"
                                    type="text"
                                    value={CC}
                                    disabled
                                />
                            </div>
                        </div> */}

                        <div className="form-group">
                            <label className="" htmlFor="Subject">
                                Subject
                            </label>
                            <div className="col-md-9">
                                <input
                                    className="form-control "
                                    id="Subject"
                                    name="Subject"
                                    readOnly="readonly"
                                    type="text"
                                    value={Subject}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="" htmlFor="Subject">
                                Body
                            </label>
                            <div className="col-md-9">
                                
                                <textarea
                            value={Body}
                            className="form-control"
                            style={{ height: "auto", overflow: "auto", resize: "none", minHeight: "400px", maxHeight: "400px" }}
                            
                          
                            >
                                </textarea>
                                  
                                

                            </div>
                        </div>
                        <div className="text-end">
                        {!isLoaded ? (
                                        <button type="submit" id="addRowButton" className="btn btn-primary" style={{ cursor: "pointer" }}>
                                            Shoot
                                        </button>
                                    ) : (
                                        <button class="btn btn-primary" type="button" disabled>
                                            <span
                                                class="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                            Loading...
                                        </button>
                                    )}
                          

                        </div>
                    </div>
                </form>
            </div>

        </>
    )
}
