import React, { useState, useContext, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import $ from "jquery";
import EmailTemplateService from "../../../Services/EmailTemplateService";
import "datatables.net";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
// import Button from "@material-ui/core/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Preloader from "../../Preloader";
// import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
// import DialogContent from "@mui/material/DialogContent";
// import DialogActions from "@mui/material/DialogActions";
// import CloseIcon from "@material-ui/icons/Close";
import { ToastContainer, toast } from "react-toastify";
import HeaderDashboard from "../DashBoard/HeaderDashboard";
import ScrollToTop from "react-scroll-to-top";
import LoginView from "../Login/LoginView";
import Cookies from "universal-cookie";

function EmailTemplateList(props) {
  //const { setCookie, removeCookie, cookies } = useContext(AuthContext);
  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  const EmailtemplateServieObj = new EmailTemplateService();

  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);

//   var UserValidate = cookies.token;
  const DeleteSolution = (val) => {
    swal({
      title: "Are you sure?",
      text: "You Want to Delete this Solution?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        EmailtemplateServieObj.DeleteTemplate(val)
          .then((response) => {
            return response;
          })
          .then((data) => {
            {
              swal("Solution has been deleted!", {
                icon: "success",
              });
              // window.location.reload(false);
              setGridRender();
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  let redriect = useNavigate();

  const navigateToContacts = (val) => {
    
    
    redriect("/EmailTemplateCreate", { state: val });
  };
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [Auth, SetAuth] = useState();
  const cookies = new Cookies();

  useEffect(() => {
    SetAuth(cookies.get("userID"));
    EmailtemplateServieObj.getData()
      .then((res) => {
        if (
          res != null &&
          res.data.emailTemplateList != null &&
          res.data.emailTemplateList.length > 0
        ) {
          setData(res.data.emailTemplateList);
          // $('#templateTables').DataTable().destroy();
        }
        setTimeout(() => {
            $("#preloader").hide();
          }, 1000);
      })

      .catch((err) => {
        console.log(err);
      });
  }, [GridRender]);

//   useEffect(() => {    
//     if (cookies.tempEmailTempCreate == 1) {
//       removeCookie("tempEmailTempCreate");
//       Toast("Email Template Created Succesfully", "success");
//     }
//     if (cookies.tempEmailTempCreate == 2) {
//       removeCookie("tempEmailTempCreate");
//       Toast("Email Template Updated Succesfully", "success");
//     }
//     if (data.length !== 0) {
//       console.log(data);
//       setIsLoading(false);
//       $("#templateTable").DataTable();
//     }
//   }, [data]);

  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const columns = [
    {
      name: "emailTemplateID",
      label: "emailTemplateID",
      options: {
        filter: true,
        sort: true,
        display: "none",
      },
    },
    {
      name: "moduleName",
      label: "Module Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "moduleFunctionalityName",
      label: "Functionality Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "subject",
      label: "Subject",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "OPTION",
      label: "Option",
      options: {
        filter: true,
        sort: false,
        empty: true,

        responsive: true,

        customBodyRender: (values, tableMeta) => {
          const value = tableMeta.rowData[0];
          return (
            <div className="center">
              <IconButton
                title="Edit"
                onClick={() => navigateToContacts(value)}
              >
                <EditIcon />
              </IconButton>
              <IconButton title="Delete" onClick={() => DeleteSolution(value)}>
                <DeleteIcon />
              </IconButton>
            </div>
          );
        },
      },
    },
  ];
  const HeaderElements = () => (
    <>
      <Tooltip title="Add Template">
        <label htmlFor="icon-button-file">
          <IconButton variant="primary" onClick={() => navigateToContacts(0)}>
            <AddIcon> </AddIcon>
          </IconButton>
        </label>
      </Tooltip>
    </>
  );
  return Auth ?(
    <>
      <Preloader/>
    <HeaderDashboard/>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />

        <div class="main-panel">
          <div class="content">
          
              <div class="row">
                <div class="col-md-12">
                  <div class="card grid">
                    <div class="card-body">
                    <div className="page-inner">
                      <div className="table-responsive">
                        <MUIDataTable
                          data={data}
                          title="Email Template List"
                          columns={columns}
                          options={{
                            responsive: true,
                            download: false,
                            print: false,
                            selectableRows: false,
                            filter: true,
                            viewColumns: true,
                            customToolbar: () => (
                              <>
                                <HeaderElements />
                              </>
                            ),
                          }}
                        />
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          
          </div>
        </div>
      <footer className="bg-dark text-light">
        {/* Start Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            <div className="footer-bottom-box">
              <div className="row">
                <div className="col-lg-12">
                  <p>
                    © Copyright 2012 - {new Date().getFullYear()}. All Rights
                    Reserved by{" "}
                    <a href="/Dashboardview"> Vaanam Technologies Pvt Ltd.</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollToTop smooth color="#2b5690" />
        {/* End Footer Bottom */}
      </footer>
    </>
  ) : (
    <LoginView />
  );
}

export default EmailTemplateList;
